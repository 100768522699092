import React, { useCallback, useEffect, useState } from 'react';

import COLORS from '../../../constants/colors';
import { createFiltersTree } from '../../../lib/utils/filters';

import { useStoreFilter } from '../../contexts';
import FilterForm from './FilterForm';
import FilterList from './FilterList';
import NewFilterBtn from './NewFilterBtn';

import FilterStatusDropdown from './FilterStatusDropdown';

import '../main/Main.css';
import './CreateFiltersPage.css';

export default function CreateFiltersPage() {
    const { selectedFilterId, error, filters } = useStoreFilter();
    const [ showNewFilterInput, setShowNewFilterInput ] = useState(false);

    const filtersTree = createFiltersTree(filters).reverse();

    useEffect(() => setShowNewFilterInput(showNewFilterInput && !selectedFilterId));

    const handleClose = useCallback(() => setShowNewFilterInput(false), []);

    return (
        <div className='ms-Grid page-container'>
            <div className='filter-toolbar'>
                <FilterStatusDropdown />
                <NewFilterBtn setShowNewFilterInput={setShowNewFilterInput} />
            </div>
            {filtersTree.map((filter, i) => (
                <div
                    key={filter.id}
                    className='filter-panel'
                    style={{ backgroundColor: COLORS[i % COLORS.length] }}
                >
                    <FilterList filters={[ filter ]} depth={0} />
                </div>
            ))}
            {showNewFilterInput && (
                <div
                    id='new-root-filter-input'
                    className='filter-panel selected-filter'
                >
                    {!!error && <div className='error-message'>{error}</div>}
                    <FilterForm handleClose={handleClose} />
                </div>
            )}
        </div>
    );
}
