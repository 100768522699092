import React, { useEffect, useRef, useState }   from 'react';
import { DOCUMENT_STATUSES }                    from '../../../constants/meta';
import { getCurrentStatus, setDocumentStatus }  from '../../../lib/utils/documentStatus';

import './FilterStatusDropdown.css';

function FilterStatusDropdown() {
    const [ isOpen, setIsOpen ] = useState(false);
    const [ selectedOption, setSelectedOption ] = useState('Not digitized');

    useEffect(() => {
        getStatus();
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', keyDownListener);

        return () => {
            document.removeEventListener('keydown', keyDownListener);
        };
    }, [ isOpen ]);

    async function getStatus() {
        try {
            const status = await getCurrentStatus();

            setSelectedOption(status);
        } catch (error) {
            console.log('GET_STATUS', error);
        }
    }

    const wrapper = useRef();

    function keyDownListener({ code }) {
        if (code === 'Escape') {
            if (isOpen) {
                toggleDropdown();
            }
        }
    }

    function toggleDropdown() {
        setIsOpen(prev => !prev);
    }

    function onOptionClicked(value) {
        return async () => {
            try {
                setIsOpen(false);
                setSelectedOption(value);
                await setDocumentStatus(value);
            } catch (error) {
                console.log('SET_STATUS', error);
            }
        };
    }

    return (
        <div
            className   = 'dropdown-container'
        >
            <div ref         = {wrapper} className='dropdown-wrapper'>
                <div
                    onClick   = {toggleDropdown}
                    className = 'dropdown-item'
                >
                    {selectedOption}
                    <div className='dropdown-arrow'>
                        <img
                            className={isOpen ? 'rotate' : ''}
                            src='assets/up-arrow.svg'
                        />
                    </div>
                </div>
                <div>
                    <div className={isOpen ? 'dropdown-list' : 'dropdown-list hidden'}>
                        {
                            DOCUMENT_STATUSES.map((status) => {
                                if (status !== selectedOption) {
                                    return (
                                        <div
                                            key       = {status}
                                            onClick   = {onOptionClicked(status)}
                                            className = 'dropdown-item'
                                        >
                                            {status}
                                        </div>
                                    );
                                }

                                return null;
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FilterStatusDropdown;
