import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useId } from '@fluentui/react-hooks';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { TextField } from '@fluentui/react/lib/TextField';
import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';

import api from '../../../api/apiSingleton';
import { ISSUE_TYPES } from '../../../constants/feedback';

import { useStoreAuth } from '../../contexts/authContext';
import FeedbackTypeDropdown from './FeedbackTypeDropdown';
import FeedbackSendingStatus, { SEND_STATUS } from './FeedbackSendingStatus';

import './FeedbackDialog.css';

const dialogStyles = {
    main : {
        maxWidth  : 500,
        width     : '80%',
        selectors : {
            '@media (min-width: 480px)' : {
                width    : '80%',
                maxWidth : 500
            }
        }
    }
};
const dialogContentProps = {
    type                 : DialogType.normal,
    title                : 'Contact support',
    closeButtonAriaLabel : 'Close'
};
const dialogFooterStyles = {
    actionsRight : {
        display        : 'flex',
        alignItems     : 'center',
        justifyContent : 'flex-end'
    }
};

const textAreaStyle = {
    subComponentStyles : {
        label : {
            root : {
                fontWeight : 400
            }
        }
    }
};
const textAreaProps = {
    label     : 'Type your question about iGRD tool here',
    multiline : true,
    rows      : 10,
    resizable : false,
    styles    : textAreaStyle,
    required  : true
};

async function sendFeedback(senderEmail, issueType, text) {
    try {
        await api.feedback.send({ senderEmail, issueType, text });
    } catch (error) {
        console.error(error);

        return {
            type    : SEND_STATUS.ERROR,
            message : 'An error occured while sending the message.'
        };
    }

    return {
        type    : SEND_STATUS.SUCCESS,
        message : 'Your message has been sent successfully!'
    };
}

export default function FeedbackDialog(props) {
    const { hidden, onDismiss } = props;
    const { profile } = useStoreAuth();
    const [ sendStatus, setSendStatus ] = useState({});
    const [ issueType, setIssueType ] = useState(ISSUE_TYPES.CONTENT);
    const [ feedbackText, setFeedbackText ] = useState('');
    const labelId = useId('dialogLabel');
    const handleSubmit = useCallback(async (e) => {
        e.preventDefault();
        setSendStatus({ type: SEND_STATUS.PENDING });

        const status = await sendFeedback(profile.mail, issueType, feedbackText);

        setSendStatus(status);
    });
    const handleChange = useCallback((e) => {
        setFeedbackText(e.target.value);
    });
    const handleDismiss = useCallback((e) => {
        setFeedbackText('');
        setSendStatus({});
        onDismiss(e);
    });
    const modalProps = useMemo(
        () => ({
            titleAriaId : labelId,
            isBlocking  : true,
            styles      : dialogStyles
        }),
        [ labelId ]
    );

    useEffect(() => setIssueType(ISSUE_TYPES.CONTENT), [ hidden ]);

    return (
        <Dialog
            hidden={hidden}
            onDismiss={handleDismiss}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
        >
            {
                sendStatus?.type ?
                    <FeedbackSendingStatus {...sendStatus} /> :
                    <form onSubmit={handleSubmit}>
                        <div className='issue-type-selection'>
                            <div>Please specify the issue</div>
                            <FeedbackTypeDropdown onChange={setIssueType} />
                        </div>
                        <TextField value={feedbackText} onChange={handleChange} {...textAreaProps} />
                        <DialogFooter styles={dialogFooterStyles}>
                            <PrimaryButton type='submit' text='Send' />
                        </DialogFooter>
                    </form>
            }
        </Dialog>
    );
}

FeedbackDialog.propTypes = {
    hidden    : PropTypes.bool.isRequired,
    onDismiss : PropTypes.func.isRequired
};
