import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { PrimaryButton } from '@fluentui/react';

import { PROGRESS_KEY, PROGRESS_TEXT } from '../../../constants/progress';

import { useStoreAuth } from '../../contexts/authContext';
import Version from '../Version';
import Header from './Header';
import FeatureList from './FeatureList';
import Progress from './Progress';
import RefsUpdateError from './RefsUpdateError';

export default function WelcomePage({
    title,
    isOfficeInitialized,
    setMessageStep,
    messageStep
}) {
    const { profile, isAuth, api } = useStoreAuth();

    if (!isOfficeInitialized || !isAuth) {
        return (
            <Progress
                title   = {title}
                logo    = 'assets/logo.svg'
                message = {PROGRESS_TEXT[messageStep]}
            />
        );
    }

    if (messageStep === PROGRESS_KEY.REFERENCE_UPDATE_ERROR) {
        return (
            <RefsUpdateError
                title   = {title}
                setStep = {setMessageStep}
                logo    = 'assets/logo.svg'
            />
        );
    }

    return (
        <div className='ms-welcome'>
            <Header logo='assets/logo.svg' title={title} />
            <FeatureList message='Discover what Office Add-ins can do for you today!'>
                {
                    profile
                        ? (
                            <Link to='/main'>
                                <PrimaryButton className='ms-welcome__action'>
                                    Get started
                                </PrimaryButton>
                            </Link>
                        )
                        : (
                            <PrimaryButton
                                className   = 'ms-welcome__action'
                                // eslint-disable-next-line react/jsx-handler-names
                                onClick     = {api.authUser}
                            >
                                Sign in
                            </PrimaryButton>
                        )
                }
            </FeatureList>
            <Version />
        </div>
    );
}

WelcomePage.propTypes = {
    isOfficeInitialized : PropTypes.bool.isRequired,
    setMessageStep      : PropTypes.func.isRequired,
    messageStep         : PropTypes.string,
    title               : PropTypes.string.isRequired
};

WelcomePage.defaultProps = {
    messageStep : ''
};
