import * as React from 'react';
import PropTypes from 'prop-types';

export default function Header(props) {
    const { title, logo } = props;

    return (
        <section className='ms-welcome__header ms-u-fadeIn500'>
            <img
                height='150'
                src={logo}
                alt={title}
                title={title}
            />
        </section>
    );
}

Header.propTypes = {
    title : PropTypes.string.isRequired,
    logo  : PropTypes.string.isRequired
};
