import apiSingleton from '../../api/apiSingleton';
import { PUBLIC_ID_XML_NAMESPACE } from '../../constants/meta';
import { NO_CUSTOM_XML_PART_FOUND_MESSAGE } from '../../constants/errors';
import {
    getCustomXmlPartByNamespace,
    getCustomXmlPartValue,
    setCustomXmlPart
} from './customXmlParts';
import { getDocumentByUrl } from './document';

function buildPublicIdXml(publicId) {
    return `<igrdId xmlns="${PUBLIC_ID_XML_NAMESPACE}">${publicId}</igrdId>`;
}

function getPublicIdCustomXmlPart() {
    return getCustomXmlPartByNamespace(PUBLIC_ID_XML_NAMESPACE);
}

export async function getDocumentPublicId() {
    const publicIdCustomXmlPart = await getPublicIdCustomXmlPart();
    const publicIdXml = await getCustomXmlPartValue(publicIdCustomXmlPart);
    const publicId = publicIdXml.split('>')[1].split('<')[0];

    return publicId;
}

export async function getDocumentPublicIdIfExists() {
    let res;

    try {
        res = await getDocumentPublicId();
    }catch (e) {}; // eslint-disable-line

    return res;
}

export async function hasPublicId() {
    try {
        await getDocumentPublicId();

        return true;
    } catch (error) {
        if (error.message === NO_CUSTOM_XML_PART_FOUND_MESSAGE) {
            return false;
        }

        console.log(error);

        throw error;
    }
}

export async function setDocumentPublicId(publicId) {
    const publicIdXml = buildPublicIdXml(publicId);

    await setCustomXmlPart(publicIdXml);

    return publicId;
}

export async function setPublicIdIfExistsByUrl() {
    try {
        const documentPublicId = await getDocumentPublicIdIfExists();
        const data = { url: null, documentPublicId };
        const { id, publicId } = await getDocumentByUrl();

        await setDocumentPublicId(publicId);
        await apiSingleton.documents.updateDocument({ data }, id);
    } catch (error) {
        console.log(error);
    }
}

export async function removePublicId() {
    const publicIdXml = await getPublicIdCustomXmlPart();

    return new Promise((resolve) => publicIdXml.deleteAsync(() => resolve()));
}
