import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useStoreFilter } from '../../contexts';

import { DEPTH_LIMIT } from '../../../constants/filters';

import './FilterComponent.css';

export default function FilterComponentField(props) {
    const { filter, depth, value, editingEnabled, setFilterValue } = props;
    const { error, api } = useStoreFilter();

    const inputEl = useRef(null);

    useEffect(() => {
        if (editingEnabled) inputEl.current.focus();
    });

    const isRoot = !filter.parentId;
    const inputClasses = [ 'filter-input', (!editingEnabled && 'readonly') ]
        .filter(className => !!className)
        .join(' ');
    const inputStyle = {
        fontWeight : isRoot ? '600' : 'regular',
        cursor     : editingEnabled ? 'text' : 'pointer'
    };

    const handleFilterValueChange = useCallback((e) => {
        if (error) api.setError('');
        setFilterValue(e.target.value);
    }, [ error ]);

    const handleClick = useCallback(
        () => api.setSelectedFilterId(filter.id),
        [ filter ]
    );

    return (
        <input
            ref={inputEl}
            className={inputClasses}
            value={value}
            readOnly={!editingEnabled}
            style={inputStyle}
            autoComplete='off'
            onChange={handleFilterValueChange}
            onClick={!editingEnabled && depth < DEPTH_LIMIT ? handleClick : null}
        />
    );
}

FilterComponentField.propTypes = {
    filter : PropTypes.shape({
        id       : PropTypes.number.isRequired,
        parentId : PropTypes.number
    }).isRequired,
    depth          : PropTypes.number.isRequired,
    value          : PropTypes.string.isRequired,
    editingEnabled : PropTypes.bool.isRequired,
    setFilterValue : PropTypes.func.isRequired
};
