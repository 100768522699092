import React from 'react';
import PropTypes from 'prop-types';
import { ContextualMenuItemType, IconButton, registerIcons } from '@fluentui/react';

registerIcons({
    icons : {
        'edit-filter-icon' : (
            <img src='assets/edit-filter-icon.svg' alt='' />
        ),
        'edit-filter-icon-disabled' : (
            <img src='assets/edit-filter-icon-disabled.svg' alt='' />
        ),
        'delete-filter-icon' : (
            <img src='assets/delete-filter-icon.svg' alt='' />
        )
    }
});

export default function FilterDropdownMenu(props) {
    const {
        editingEnabled,
        enableEditing,
        deleteFilter
    } = props;

    const menuProps = {
        items : [
            {
                key       : 'edit',
                text      : 'Edit Filter',
                iconProps : {
                    iconName : `edit-filter-icon${editingEnabled ? '-disabled' : ''}`,
                    style    : { textAlign: 'center' }
                },
                onClick  : enableEditing,
                disabled : editingEnabled
            },
            {
                key      : 'divider',
                itemType : ContextualMenuItemType.Divider
            },
            {
                key       : 'delete',
                text      : 'Delete Filter',
                iconProps : {
                    iconName : 'delete-filter-icon',
                    style    : { textAlign: 'center' }
                },
                onClick : deleteFilter
            }
        ],
        isBeakVisible : false
    };

    return (
        <IconButton
            className='filter-menu'
            iconProps={{
                iconName : 'More',
                styles   : {
                    root : {
                        fontWeight : 600,
                        color      : '#5F6368',
                        ':hover'   : {
                            color : '#94419E'
                        }
                    }
                }
            }}
            menuProps={menuProps}
            menuIconProps={{
                style : { display: 'none', padding: 0 }
            }}
            styles={{
                root : {
                    height  : '10px',
                    padding : 0,
                    margin  : '0 4px'
                },
                rootHovered : {
                    background : '#E8E6E4'
                },
                rootPressed : {
                    background : 'none'
                }
            }}
        />
    );
}

FilterDropdownMenu.propTypes = {
    editingEnabled : PropTypes.bool.isRequired,
    enableEditing  : PropTypes.func.isRequired,
    deleteFilter   : PropTypes.func.isRequired
};
