import { URL } from '../constants/api';
import Base from './Base';

export default class Feedback extends Base {
    send = async (body) => {
        const response = await this.apiClient.post({ endpoint: URL.FEEDBACK, body });

        return response;
    }
}
