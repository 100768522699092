import React from 'react';
import PropTypes from 'prop-types';

const listItems = [
    {
        /* eslint-disable-next-line more/no-hardcoded-configuration-data */
        markerImage   : 'treeview.svg',
        primaryText   : 'Create filters',
        secondaryText : 'Based on the document, create the structure of the filter system to markup the desired text blocks of the content'
    },
    {
        /* eslint-disable-next-line more/no-hardcoded-configuration-data */
        markerImage   : 'markup.svg',
        primaryText   : 'Markup',
        secondaryText : 'With the markup, rules define text blocks to be displayed in the generated document based on the filter selection'
    },
    {
        /* eslint-disable-next-line more/no-hardcoded-configuration-data */
        markerImage   : 'generate.svg',
        primaryText   : 'Generate',
        secondaryText : 'Select the desired content in your output dossier by selecting the relevant filters'
    }
];

export default function FeatureList(props) {
    const { children } = props;

    const renderedListItems = listItems.map((item, index) => (
        /* eslint-disable-next-line react/no-array-index-key */
        <li className='ms-ListItem' key={index} style={{ padding: 10 }}>
            <div className='feature-marker'>
                <img src={`assets/${item.markerImage}`} alt='' />
            </div>
            <div>
                <h3>{item.primaryText}</h3>
                <p>{item.secondaryText}</p>
            </div>
        </li>
    ));

    return (
        <main className='ms-welcome__main'>
            <ul className='ms-List ms-welcome__features ms-u-slideUpIn10'>
                {renderedListItems}
            </ul>
            {children}
        </main>
    );
}

FeatureList.propTypes = {
    children : PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};
