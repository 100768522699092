import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './components/App';

import './taskpane.css';

/* eslint-disable-next-line more/no-window */
const { APP_CONF } = window;

if (APP_CONF.sentry) {
    Sentry.init({
        ...APP_CONF.sentry,
        integrations : [ new BrowserTracing() ]
    });
}

Office.onReady(async () => {
    await runIfOffice();
});

async function runIfOffice() {
    const RETRY_TIMEOUT_MS = 200;
    const RENDER_TIMEOUT_MS = 2500;

    /* eslint-disable-next-line more/no-window */
    if (window.Office) {
        setTimeout(() => {
            render();
        }, RENDER_TIMEOUT_MS);
        Office.initialize = () => {};

        const info = await Office.onReady();

        console.info(`Office.js is now ready in ${info.host} on ${info.platform}`);
        render();
    } else {
        setTimeout(runIfOffice, RETRY_TIMEOUT_MS);
    }
}

function render() {
    ReactDOM.render(
        <App />,
        document.getElementById('container')
    );
}

if (module.hot) {
    module.hot.accept('./components/App', () => {
        Office.onReady(async () => {
            render();
        });
    });
}
