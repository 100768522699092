/* eslint-disable max-lines-per-function */
import React, {
    useContext,
    useEffect,
    useRef,
    useState
}                         from 'react';
import PropTypes          from 'prop-types';

import { v4 as uuid }                  from 'uuid';
import { DialogService }               from '../../lib/DialogService';
import { getDocumentPublicIdIfExists } from '../../lib/utils/documentPublicId';
import api                             from '../../api/apiSingleton';

import { SESSION_ID_KEY } from '../../constants/auth';

const AuthContext = React.createContext();

export function useStoreAuth() {
    return useContext(AuthContext);
}

const SLEEP_TIME = 1000;

export function AuthContextProvider({ children }) {
    const [ isAuth, setIsAuth ]         = useState(false);
    const [ sessionID, setSessionID ]   = useState(null);
    const [ profile, setProfile ]       = useState(null);
    const request = useRef(true);

    useEffect(() => {
        logIn();
    }, []);

    async function logIn() {
        setIsAuth(false);
        const localStorageSessionId = localStorage.getItem(SESSION_ID_KEY);

        try {
            if (localStorageSessionId) {
                setSessionID(localStorageSessionId);

                const { data } = await getRole(localStorageSessionId);

                setProfile(data);
            } else {
                await authUser();
            }
        } catch (error) {
            if (error.code === 'NON_EXISTENT_SESSION') {
                localStorage.removeItem(SESSION_ID_KEY);

                await authUser();
            }
        }

        setIsAuth(true);
    }

    async function logout() {
        try {
            const response = await api.auth.logout();

            setProfile(null);
            localStorage.removeItem(SESSION_ID_KEY);

            return response;
        } catch (error) {
            console.log(error);
        }
    }

    async function authUser() {
        try {
            const id = uuid();

            setSessionID(id);
            localStorage.setItem(SESSION_ID_KEY, id);

            await DialogService.openDialog({ id, closeAction, openAction });

            const responce = await getProfileInterval({ id, limit: 100 });

            await DialogService.closeDialog();

            setProfile(responce);
        } catch (error) {
            console.log('AUTH', error);
        }
    }

    function closeAction() {
        localStorage.removeItem(SESSION_ID_KEY);
        request.current = false;
    }

    function openAction() {
        request.current = true;
    }

    async function getRole(id) {
        const documentPublicId = await getDocumentPublicIdIfExists();
        const response = await api.auth.getRole({ sessionId: id, documentPublicId });

        return response;
    }

    async function getProfileInterval({ id, limit }) {
        try {
            await sleep(SLEEP_TIME);
            const response = await getRole(id);

            if (response.status) {
                return response.data;
            }

            if (limit > 0 && request.current) {
                return getProfileInterval({ id, limit: limit - 1 });
            }
        } catch (error) {
            if (limit > 0 && request.current) {
                return getProfileInterval({ id, limit: limit - 1 });
            }
        }
    }

    async function sleep(time) {
        return new Promise(res => setTimeout(() => res(), time));
    }

    return (
        <AuthContext.Provider
            value={{
                isAuth,
                sessionID,
                profile,
                api : {
                    logout,
                    authUser
                }
            }}
        >
            { children }
        </AuthContext.Provider>
    );
}

AuthContextProvider.propTypes = {
    children : PropTypes.element.isRequired
};
