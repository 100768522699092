import { NO_CUSTOM_XML_PART_FOUND_MESSAGE } from '../../constants/errors';
import { saveDocument } from './document';

export function getCustomXmlPartByNamespace(namespace) {
    const { customXmlParts } = Office.context.document;

    return new Promise((resolve, reject) => {
        customXmlParts.getByNamespaceAsync(
            namespace,
            (result) => {
                if (result.status !== Office.AsyncResultStatus.Succeeded) {
                    const { error } = result;

                    return reject(new Error(`${error.name}: ${error.message}`));
                }

                if (result.value[0]) {
                    resolve(result.value[0]);
                } else {
                    reject(new Error(NO_CUSTOM_XML_PART_FOUND_MESSAGE));
                }
            }
        );
    });
}

export function setCustomXmlPart(xml) {
    return new Promise((resolve, reject) => {
        Office.context.document.customXmlParts.addAsync(
            xml,
            (result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    saveDocument();

                    resolve();
                } else {
                    const { error } = result;

                    reject(new Error(`${error.name}: ${error.message}`));
                }
            }
        );
    });
}

export function getCustomXmlPartValue(customXmlPart) {
    return new Promise((resolve, reject) => {
        customXmlPart.getXmlAsync((xmlResult) => {
            if (xmlResult.status !== Office.AsyncResultStatus.Succeeded) {
                const { error } = xmlResult;

                return reject(new Error(`${error.name}: ${error.message}`));
            }

            resolve(xmlResult.value);
        });
    });
}
