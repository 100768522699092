import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@fluentui/react';

import { STATE } from '../../../lib/filter-selector/filterStates';

import { useStoreMarkup } from '../../contexts';

export default function FilterCheckbox(props) {
    const { selectedRule, filterId, filterSelector } = props;
    const { api } = useStoreMarkup();

    const { state: selectionState } = filterSelector.getFilterState(filterId);
    const checkboxStyles = { checkbox: { margin: 0 } };

    function handleCheck() {
        filterSelector.toggle(filterId, true);
        api.selectFilters(selectedRule, filterSelector.getState());
    }

    return (
        <Checkbox
            indeterminate={selectionState === STATE.INTERMEDIATE}
            checked={selectionState === STATE.CHECKED}
            styles={checkboxStyles}
            checkmarkIconProps={{
                iconName : selectionState === STATE.INTERMEDIATE ? 'Remove' : 'Accept'
            }}
            onChange={handleCheck}
        />
    );
}

FilterCheckbox.propTypes = {
    selectedRule   : PropTypes.number.isRequired,
    filterId       : PropTypes.number.isRequired,
    filterSelector : PropTypes.object.isRequired
};
