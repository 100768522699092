import { createFullUrlToArchive } from '../utils/createFullUrlToArchive';

const regEx           = /\d+\.+\s*/;
const endNoteClass    = '.EndNoteBibliography';
const fileName        = 'References';

export async function getReferencesFromDocument(context) {
    try {
        const html = context.document.body.getHtml();

        await context.sync();

        const el = document.createElement('html');

        el.innerHTML = html.value;

        const referencesElements = el.querySelectorAll(endNoteClass);

        return checkReferences(referencesElements);
    } catch (error) {
        console.log('getReferences', error);
    }
}

export function loadReferencesFile(archiveName) {
    try {
        const urlToFile = createFullUrlToArchive(archiveName);

        const link = document.createElement('a');

        link.target = '_blank';
        link.href = urlToFile;
        link.download = fileName;

        link.click();
    } catch (error) {
        console.log('loadReferencesFile', error);
    }
}

function checkReferences(referencesElements) {
    const references = [ ...referencesElements ]
        .map(({ textContent }) => textContent
            .replace(regEx, '')
            .trim());

    const uniqueReferences = new Set(references);

    return [ ...uniqueReferences ].filter(Boolean);
}
