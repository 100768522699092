export function createFiltersTree(filters) {
    const rootFilters = filters.filter(f => !f.parentId);

    rootFilters.reverse();

    for (const filter of rootFilters) {
        filter.subfilters = getAllSubfilters(filter, filters);
    }

    return rootFilters;
}

function getAllSubfilters(filter, allFilters) {
    const directSubfilters = getDirectSubfilters(filter, allFilters);

    for (const subfilter of directSubfilters) {
        subfilter.subfilters = getAllSubfilters(subfilter, allFilters);
    }

    return directSubfilters;
}

function getDirectSubfilters(filter, allFilters) {
    return allFilters.filter(f => f.parentId === filter.id);
}
