export default class RuleEvaluator {
    #evaluateRuleItem(ruleItem, values) {
        const [ type, selectedIds ] = ruleItem;
        const operands = selectedIds.map(id => values.has(id));

        return operands.reduce((result, operand) => {
            return type === 'All' ? (result && operand) : (result || operand);
        });
    }

    evaluateRule({ ruleItems, operators }, values) {
        const ruleItemsResults = ruleItems.map(item => this.#evaluateRuleItem(item, values));

        return !!ruleItemsResults.reduce((result, item, i) => {
            return (operators[i - 1] === 'or') ? (result || item) : (result && item);
        });
    }
}
