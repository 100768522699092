import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@fluentui/react';

import { useStoreMarkup } from '../../contexts';

import './RulesChain.css';

export default function RuleItem(props) {
    const { itemId, type, isSelected, setSelectedRule } = props;
    const { api } = useStoreMarkup();

    const handleRemove = useCallback(() => {
        setSelectedRule(null);
        api.removeRuleItem(itemId);
    }, [ itemId ]);

    const handleClick = useCallback(() => setSelectedRule(itemId), [ itemId ]);

    return (
        <div
            className={`rule-item${isSelected ? ' selected-rule' : ''}`}
            onClick={!isSelected ? handleClick : null}
        >
            <span>{type}</span>
            {isSelected && <Icon iconName='Cancel' onClick={handleRemove} />}
        </div>
    );
}

RuleItem.propTypes = {
    itemId          : PropTypes.number.isRequired,
    type            : PropTypes.string.isRequired,
    isSelected      : PropTypes.bool.isRequired,
    setSelectedRule : PropTypes.func.isRequired
};
