import React, { useCallback, useEffect, useState } from 'react';
import { useBoolean } from '@fluentui/react-hooks';
import { PrimaryButton } from '@fluentui/react';

import { LS_SELECTED_FILTERS_KEY } from '../../../constants/meta';
import { STRATEGIES } from '../../../constants/preselected';
import COLORS from '../../../constants/colors';
import MarkupService from '../../../lib/MarkupService';
import { STATE } from '../../../lib/filter-selector/filterStates';
import FilterSelector from '../../../lib/filter-selector/FilterSelector';
import { createFiltersTree } from '../../../lib/utils/filters';
import { createNewDocument } from '../../../lib/utils/markup';

import { useStoreFilter, useStoreGenerate } from '../../contexts';
import GenerateSpinner from './GenerateSpinner';
import GenerateChecklist from './GenerateChecklist';
import StrategyDropdown from './StrategyDropdown';
import SupportButton from './SupportButton';
import FeedbackDialog from './FeedbackDialog';

import '../main/Main.css';
import '../markup/MarkupPage.css';
import './GeneratePage.css';

function handleGenerate(filterSelections, allFilters, setLoading, setLoadedPercentage) {
    setLoadedPercentage(0);
    setLoading(true);
    Word.run(async (context) => {
        const ids = await MarkupService.getContentControlIdsToRemove(
            context,
            filterSelections,
            allFilters
        );

        const selectedFiltersWithValue = filterSelections.map(i =>  {
            return {
                value : allFilters.find(ai => ai.id === i.id).value,
                ...i
            };
        });

        const selectedFiltersTree = createFiltersTree(selectedFiltersWithValue).reverse();

        localStorage.setItem(
            LS_SELECTED_FILTERS_KEY,
            JSON.stringify(selectedFiltersTree)
        );

        const creationEventEmitter = createNewDocument(context, ids);

        creationEventEmitter.on('sliceLoaded', setLoadedPercentage);
        creationEventEmitter.on('documentCreated', () => setLoading(false));
        creationEventEmitter.on('error', () => setLoading(false));
    });
}

export default function GeneratePage() {
    const { filters } = useStoreFilter();
    const { filterSelections, api: generateContextApi } = useStoreGenerate();
    const [ loading, setLoading ] = useState(false);
    const [ loadedPercentage, setLoadedPercentage ] = useState(0);
    const [ strategy, setStrategy ] = useState(STRATEGIES.PREP);
    const [ hideDialog, { toggle: toggleHideDialog } ] = useBoolean(true);

    const filtersTree = createFiltersTree(filters).reverse();
    const filterSelector = new FilterSelector(filters, filterSelections);

    useEffect(() => {
        filterSelector.applyPreselectStrategy(strategy);
        generateContextApi.setFilterSelections(filterSelector.getState());
    }, [ strategy, filters ]);

    const handleGenerateClick = useCallback(() => {
        const checkedFilters = filterSelections.filter(f => f.state !== STATE.UNCHECKED);

        handleGenerate(
            checkedFilters,
            filters,
            setLoading,
            setLoadedPercentage
        );
    });

    return loading ? <GenerateSpinner loadedPercentage={loadedPercentage} /> : (
        <>
            <div className='ms-Grid page-container'>
                <div className='strategy-selection-block'>
                    <p>Choose your strategy</p>
                    <StrategyDropdown onChange={setStrategy} />
                </div>
                {filtersTree.map((filter, i) => (
                    <div
                        key={filter.id}
                        className='markup-filter-panel'
                        style={{ backgroundColor: COLORS[i % COLORS.length] }}
                    >
                        <GenerateChecklist
                            filters={[ filter ]}
                            depth={0}
                            filterSelector={filterSelector}
                            isOpen={i === 0}
                        />
                    </div>
                ))}
            </div>
            <div className='generate-footer'>
                <PrimaryButton
                    className='generate-btn'
                    primaryDisabled={loading}
                    onClick={handleGenerateClick}
                >
                    Generate
                </PrimaryButton>
                <SupportButton onClick={toggleHideDialog} />
            </div>

            <FeedbackDialog hidden={hideDialog} onDismiss={toggleHideDialog} />
        </>
    );
}
