import React from 'react';

import { Tooltip } from '../custom-tooltip';

export default function MarkupTips() {
    const tipButtons = {
        any : {
            id       : 'Any',
            children : 'Any',
            title    : (
                <>
                    With <b>“Any”</b> rule if at least one of the
                    chexboxes is selected, text block will appear
                </>
            )
        },
        all : {
            id       : 'All',
            children : 'All',
            title    : (
                <>
                    With <b>“All”</b> rule if all of the “selected”
                    chexboxes are selected, text block will appear
                </>
            )
        }
    };

    return (
        <p>
            This text block will appear in the generated document if
            all <Tooltip {...tipButtons.any} /> and <Tooltip {...tipButtons.all} /> rules are true
        </p>
    );
}
