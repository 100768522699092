import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, ResponsiveMode } from '@fluentui/react';

import { STRATEGIES } from '../../../constants/preselected';

const dropdownStyles = {
    title : {
        borderRadius : 0
    },
    dropdown : {
        minWidth  : 150,
        boxShadow : '3px 3px 4px 0 rgb(0 0 0 / 5%)'
    }
};

const options = [
    { key: STRATEGIES.PREP, text: STRATEGIES.PREP },
    { key: STRATEGIES.OP, text: STRATEGIES.OP },
    { key: STRATEGIES.NONE, text: STRATEGIES.NONE }
];

export default function StrategyDropdown(props) {
    const { onChange } = props;
    const handleStrategySelect = useCallback((e, o) => onChange(o.text), []);

    return (
        <Dropdown
            defaultSelectedKey={STRATEGIES.PREP}
            options={options}
            styles={dropdownStyles}
            responsiveMode={ResponsiveMode.large}
            onChange={handleStrategySelect}
        />
    );
}

StrategyDropdown.propTypes = {
    onChange : PropTypes.func.isRequired
};
