import React, { useState }  from 'react';
import PropTypes            from 'prop-types';

import './Logout.css';

export function Logout({ mail, action  }) {
    const [ move, setMove ] = useState(false);

    function mouseMoveStart() {
        if (!move) {
            setMove(true);
        }
    }

    function mouseMoveEnd() {
        if (move) {
            setMove(false);
        }
    }

    return (
        <div
            className='logoutWrapper'
        >
            <span className='mail'>{mail}</span>
            <div className='button'>
                <div
                    onClick         = {action}
                    className       = 'imageWrapper'
                    onMouseEnter    = {mouseMoveStart}
                    onMouseLeave    = {mouseMoveEnd}
                >
                    <img src={move ? '/assets/logout-active.svg' : '/assets/logout.svg'} />
                </div>
            </div>
        </div>
    );
}

Logout.propTypes = {
    mail   : PropTypes.string,
    action : PropTypes.func
};

Logout.defaultProps = {
    mail   : 'MUST BE MAIL',
    action : () => {}
};
