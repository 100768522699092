import {
    INVALID_DOC_STATUS_ERROR_MESSAGE,
    NO_CUSTOM_XML_PART_FOUND_MESSAGE
} from '../../constants/errors';
import {
    DOCUMENT_STATUSES,
    DOC_STATUS_XML_NAMESPACE
} from '../../constants/meta';
import {
    getCustomXmlPartByNamespace,
    getCustomXmlPartValue,
    setCustomXmlPart
} from './customXmlParts';

function buildDocumentStatusXml(status) {
    return `<igrdStatus xmlns="${DOC_STATUS_XML_NAMESPACE}">${status}</igrdStatus>`;
}

function getStatusCustomXmlPart() {
    return getCustomXmlPartByNamespace(DOC_STATUS_XML_NAMESPACE);
}

export async function getCurrentStatus() {
    try {
        const statusCustomXmlPart = await getStatusCustomXmlPart();
        const statusXml = await getCustomXmlPartValue(statusCustomXmlPart);
        const status = statusXml.split('>')[1].split('<')[0];

        return status;
    } catch (error) {
        if (error.message === NO_CUSTOM_XML_PART_FOUND_MESSAGE) {
            return 'Not digitized';
        }

        throw error;
    }
}

export async function setDocumentStatus(status) {
    if (!DOCUMENT_STATUSES.includes(status)) {
        throw new Error(INVALID_DOC_STATUS_ERROR_MESSAGE);
    }

    const newDocStatusXml = buildDocumentStatusXml(status);

    try {
        await removeDocumentStatus();
    } catch (error) {
        if (error.message !== NO_CUSTOM_XML_PART_FOUND_MESSAGE) throw error;
    }

    await setCustomXmlPart(newDocStatusXml);
}

export async function removeDocumentStatus() {
    const statusCustomXmlPart = await getStatusCustomXmlPart();

    return new Promise((resolve) => statusCustomXmlPart.deleteAsync(() => resolve()));
}
