import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useStoreFilter } from '../../contexts';

import './FilterInput.css';

export default function FilterInput(props) {
    const { isRoot, filterValue, setFilterValue } = props;
    const { error, api } = useStoreFilter();

    const handleFilterValueChange = useCallback((e) => {
        if (error) api.setError('');
        setFilterValue(e.target.value);
    }, [ error ]);

    return (
        <input
            className='filter-input'
            placeholder={isRoot ? 'New Filter' : 'New Subfilter'}
            value={filterValue}
            autoComplete='off'
            style={{ fontWeight: isRoot ? '600' : 'normal' }}
            onChange={handleFilterValueChange}
            autoFocus
        />
    );
}

FilterInput.propTypes = {
    isRoot         : PropTypes.bool.isRequired,
    filterValue    : PropTypes.string.isRequired,
    setFilterValue : PropTypes.func.isRequired
};
