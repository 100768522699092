import React from 'react';
import PropTypes from 'prop-types';

import { useStoreMarkup } from '../../contexts';

import './RulesChain.css';

export default function OperatorItem(props) {
    const { itemId, type } = props;
    const { api } = useStoreMarkup();

    function handleToggle() {
        api.toggleOperator(itemId);
    }

    return (
        <div
            className={`operator-item ${type}`}
            onClick={handleToggle}
        >
            <span className='operator-type'>{type}</span>
            <span className='operator-toggle' />
        </div>
    );
}

OperatorItem.propTypes = {
    itemId : PropTypes.number.isRequired,
    type   : PropTypes.string.isRequired
};
