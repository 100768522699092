import { URL } from '../constants/api';
import Base from './Base';

export default class Documents extends Base {
    showDocumentByUrl = async (url) => {
        const response = await this.apiClient.get({
            endpoint : URL.DOCUMENTS,
            params   : { url }
        });

        return {
            data : response.data
        };
    }

    createDocument = async () => {
        const response = await this.apiClient.post({ endpoint: URL.DOCUMENTS, body: {} });

        return response;
    }

    updateDocument = async (body, id) => {
        const response = await this.apiClient.put({ endpoint: `${URL.DOCUMENTS}/${id}`, body });

        return response;
    }

    updateDocumentContent = async (body) => {
        const response = await this.apiClient.post({
            endpoint : 'update-content',
            asJson   : false,
            body
        });

        return response;
    }

    checkDocumentContentUpdate = async (taskId) => {
        const response = await this.apiClient.get({
            endpoint : 'check-update',
            params   : { taskId }
        });

        return response;
    }
}
