/* eslint-disable react/no-multi-comp */

import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useStoreMarkup } from '../../contexts';
import RuleItem from './RuleItem';
import OperatorItem from './OperatorItem';

import './RulesChain.css';

export default function RulesChain(props) {
    const { selectedRule, setSelectedRule } = props;
    const { ruleItems, operators } = useStoreMarkup();

    useEffect(() => {
        return () => {
            setSelectedRule(null);
        };
    }, []);

    return (
        <div className='rules-chain'>
            {ruleItems.map((item, i) => (
                /* eslint-disable-next-line react/no-array-index-key */
                <Fragment key={i}>
                    <RuleItem
                        itemId={i}
                        type={item.type}
                        isSelected={selectedRule === i}
                        setSelectedRule={setSelectedRule}
                    />
                    {ruleItems.length > 1 && i < ruleItems.length - 1 && (
                        <OperatorItem itemId={i} type={operators[i]} />
                    )}
                </Fragment>
            ))}
        </div>
    );
}

RulesChain.propTypes = {
    selectedRule    : PropTypes.number,
    setSelectedRule : PropTypes.func.isRequired
};

RulesChain.defaultProps = {
    selectedRule : null
};
