import api from '../../api/apiSingleton';
import { STATE } from '../filter-selector/filterStates';
import { LS_GENERATION_PARAMS_KEY, PARENT_URL_SETTING } from '../../constants/meta';
import { base64EncArr } from './misc';
import EventEmitter from './EventEmitter';

export function getSelectedIds(filterSelections) {
    const selectedIds = [];

    for (const f of filterSelections) {
        const subfilters = filterSelections
            .filter(selection => selection.parentId === f.id);

        if (!subfilters.length && f.state === STATE.CHECKED) {
            selectedIds.push(f.id);
        }
    }

    return selectedIds;
}

export function convertRuleItems(ruleItems) {
    const converted = [];

    for (const item of ruleItems) {
        if (item.filterSelections.length) {
            converted.push([
                item.type,
                getSelectedIds(item.filterSelections)
            ]);
        }
    }

    return converted;
}

export function filterMarkup(markup, filters) {
    const existingIds = new Set(filters.map(filter => filter.id));

    let markupChanged = false;

    if (markup.operators.length >= markup.ruleItems.length) {
        /* eslint-disable-next-line no-param-reassign */
        markup.operators = markup.operators.slice(0, markup.ruleItems.length - 1);
    }

    for (const item of markup.ruleItems) {
        const selectedIds = item[1];
        const initialNumber = selectedIds.length;

        item[1] = selectedIds.filter(id => existingIds.has(id));

        if (item[1].length !== initialNumber && !markupChanged) {
            markupChanged = true;
        }
    }

    /* eslint-disable-next-line no-param-reassign */
    markup.ruleItems = markup.ruleItems.filter(item => item[1].length);

    return markupChanged;
}

export function createNewDocument(context, contentControlIdsToRemove) {
    const documentSettings = Office.context.document.settings;
    const parentUrl = documentSettings.get(PARENT_URL_SETTING);
    const creationEventEmitter = new EventEmitter();

    localStorage.setItem(
        LS_GENERATION_PARAMS_KEY,
        JSON.stringify({ parentUrl, contentControlIdsToRemove })
    );
    copyDocument(context, creationEventEmitter);

    return creationEventEmitter;
}

export function generateHtmlFiltersTree(filtersTree) {
    let html = '<p><ul>';

    function getHtmlTree(filters) {
        filters.forEach(f => {
            if (f.parentId) html += '<ul>';
            html += `<li>${f.value}`;
            if (f.subfilters.length) {
                getHtmlTree(f.subfilters);
            }

            html += '</li>';
            if (f.parentId) html += '</ul>';
        });
    }

    getHtmlTree(filtersTree);

    return html += '</ul><br/></p>';
}

async function retry(fn, maxAttempts, interval) {
    async function execute(attempt) {
        try {
            return await fn();
        } catch (err) {
            if (attempt <= maxAttempts) {
                const nextAttempt = attempt + 1;

                return delay(() => execute(nextAttempt), interval);
            }

            throw err;
        }
    }

    return execute(1);
}

function delay(fn, ms) {
    return new Promise((resolve) => setTimeout(() => resolve(fn()), ms));
}

export async function getUpdatedFieldsAndRefsOoxml() {
    /* eslint-disable-next-line more/no-hardcoded-configuration-data */
    const DOCX_MIMETYPE = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    const file = await getCompressedFile();
    const data = new FormData();
    const blob = new Blob([ new Uint8Array(file) ], { type: DOCX_MIMETYPE });

    data.append('file', blob);

    const MAX_TRIES = 60;
    const CHECK_INTERVAL_MS = 5000;
    const { taskId } = await api.documents.updateDocumentContent(data);
    const { data: responseData } = await retry(
        () => api.documents.checkDocumentContentUpdate(taskId),
        MAX_TRIES,
        CHECK_INTERVAL_MS
    );

    return responseData.base64docx;
}

function getCompressedFile(creationEventEmitter = null) {
    const SLICE_SIZE_BYTES = 100000;

    return new Promise((resolve, reject) => {
        Office.context.document.getFileAsync(
            'compressed',
            { sliceSize: SLICE_SIZE_BYTES },
            async (result) => {
                if (result.status === Office.AsyncResultStatus.Succeeded) {
                    try {
                        const documentFileData = await getAllSlices(
                            result.value,
                            creationEventEmitter
                        );

                        resolve(documentFileData);
                    } catch (error) {
                        reject(error);
                    }
                } else {
                    console.log({ status: result.status, result });

                    reject(new Error('Error while getting the file.'));
                }
            }
        );
    });
}

async function copyDocument(context, creationEventEmitter) {
    try {
        const file = await getCompressedFile(creationEventEmitter);
        const base64Docx = base64EncArr(file);
        const copiedDoc = context.application.createDocument(base64Docx);

        creationEventEmitter.emit('documentCreated');
        copiedDoc.open();

        await context.sync();
    } catch (error) {
        console.log(error);
        creationEventEmitter.emit('error');
    }
}

async function getAllSlices(file, creationEventEmitter = null) {
    let documentFileData = [];

    /* eslint-disable-next-line more/no-c-like-loops */
    for (let sliceIndex = 0; sliceIndex < file.sliceCount; sliceIndex++) {
        const slice = await getSlice(file, sliceIndex);
        /* eslint-disable-next-line no-magic-numbers */
        const loadedPercentage = Math.floor((sliceIndex + 1) / file.sliceCount * 100);

        if (creationEventEmitter) creationEventEmitter.emit('sliceLoaded', loadedPercentage);

        documentFileData = documentFileData.concat(slice);
    }

    if (!documentFileData.length) {
        throw new Error('Error while reading document. Please try it again.');
    }

    file.closeAsync();

    return documentFileData;
}

function getSlice(file, sliceIndex) {
    return new Promise((resolve, reject) => {
        file.getSliceAsync(sliceIndex, (asyncResult) => {
            if (asyncResult.status === Office.AsyncResultStatus.Succeeded) {
                resolve(asyncResult.value.data);
            } else {
                const message = `Error in reading the slice: ${sliceIndex} of the document`;

                file.closeAsync();

                reject(new Error(message));
            }
        });
    });
}
