import { URL } from '../constants/api';
import Base    from './Base';

export default class Filters extends Base {
    getReferencesFile = async (references) => {
        const response = await this.apiClient.post({
            endpoint : URL.REFERENCES,
            body     : { references }
        });

        return response;
    }
}
