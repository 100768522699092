import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

export const SEND_STATUS = {
    PENDING : 'pending',
    ERROR   : 'error',
    SUCCESS : 'success'
};

const STATUS_COLORS = {
    [SEND_STATUS.ERROR]   : '#a80000',
    [SEND_STATUS.SUCCESS] : '#107c10',
    [SEND_STATUS.PENDING] : '#0078d4'
};

const styles = {
    display        : 'flex',
    alignItems     : 'center',
    justifyContent : 'center',
    paddingTop     : '24px'
};

function getStatusStyle(type) {
    return {
        fontSize : 14,
        color    : STATUS_COLORS[type],
        ...styles
    };
}

export default function FeedbackSendingStatus(props) {
    const { type, message } = props;

    return (
        <div style={getStatusStyle(type)}>
            {
                type === SEND_STATUS.PENDING ? (
                    <Spinner size={SpinnerSize.large} />
                ) : message
            }
        </div>
    );
}

FeedbackSendingStatus.propTypes = {
    type    : PropTypes.string,
    message : PropTypes.string
};

FeedbackSendingStatus.defaultProps = {
    type    : '',
    message : ''
};
