/* eslint-disable no-magic-numbers */
import api                from '../api/apiSingleton';
import { PROGRESS_KEY }   from '../constants/progress';
import RuleEvaluator      from './RuleEvaluator';
import {
    convertRuleItems,
    filterMarkup,
    getSelectedIds,
    generateHtmlFiltersTree,
    getUpdatedFieldsAndRefsOoxml
}                         from './utils/markup';
import {
    getReferencesFromDocument,
    loadReferencesFile
}                         from './utils/references';

export default class MarkupService {
    static CC_NAME = 'Markup';

    static async #getContentControlById(context, id) {
        try {
            const cc = context.document.body.contentControls.getById(id);

            context.load(cc);
            await context.sync();

            return cc;
        } catch (error) {
            return null;
        }
    }

    static async #getContentControlsByTitle(context, title) {
        try {
            const ccs = context.document.body.contentControls.getByTitle(title);

            context.load(ccs);
            await context.sync();

            return ccs;
        } catch (error) {
            return null;
        }
    }

    static async #removeContentControlsByIds(context, ids) {
        for (const id of ids) {
            const cc = await MarkupService.#getContentControlById(context, id);

            if (!cc) continue;

            cc.delete(false);
            await context.sync();
        }
    }

    static async #removeAllContentControls(context) {
        const ccs = await MarkupService
            .#getContentControlsByTitle(context, MarkupService.CC_NAME);

        if (!ccs) return;

        for (const cc of ccs.items) {
            cc.load();
            await context.sync();
            cc.delete(true);
            await context.sync();
        }
    }

    static async #updateDocumentFieldsAndRefs(context) {
        try {
            const base64docx = await getUpdatedFieldsAndRefsOoxml();
            const body = context.document.body;

            body.insertFileFromBase64(base64docx, 'Replace');

            await context.sync();
        } catch (error) {
            console.log('Error while updating document fields', error);

            throw error;
        }
    }

    static async #insertSelectedFiltersList(context, filtersTree) {
        if (!filtersTree.length) return;

        try {
            const TAB_CHARCODE = 10;

            context.document.body.insertBreak(Word.BreakType.sectionNext, Word.InsertLocation.start);

            context.document.body.insertHtml(
                generateHtmlFiltersTree(filtersTree),
                Word.InsertLocation.start
            );

            const lists = context.document.body.lists;

            await context.load(lists);
            await context.sync();

            const currentList = lists.getFirst();

            currentList.setLevelBullet(0, Word.ListBullet.custom, TAB_CHARCODE);
            currentList.setLevelBullet(1, Word.ListBullet.checkmark);
            currentList.setLevelBullet(2, Word.ListBullet.checkmark);

            currentList.setLevelIndents(0, 0, 0);
            currentList.setLevelIndents(1, 30, -15);
            currentList.setLevelIndents(2, 45, -15);

            this.#insertParagraph(
                context,
                {
                    text     : 'Filters selected in generated document',
                    style    : 'font-size: 28pt; font-weight: 600; color: rgb(38,34,98); font-family: Calibri',
                    location : Word.InsertLocation.start
                }
            );

            await context.sync();
        } catch (error) {
            console.log(error);
        }
    }

    static async #insertParagraph(context, { text, style, location }) {
        const body = context.document.body;

        const paragraph = body.insertHtml(
            `
                <p style="${style}">${text}</p>
                <br/>
            `
            , location
        );

        return paragraph;
    }

    static async getCurrentContentControlId(context) {
        const selection = context.document.getSelection();

        context.load(selection);
        await context.sync();

        try {
            const markupCc = selection.contentControls.getFirstOrNullObject();

            await context.load(markupCc);
            await context.sync();

            if (markupCc.id) return markupCc.id;

            const parent = selection.parentContentControlOrNullObject;

            await context.load(parent);
            await context.sync();

            return parent.id;
        } catch (error) {
            console.log(error);

            return null;
        }
    }

    static async getMarkup(context, markupId) {
        const markupCc = await MarkupService.#getContentControlById(context, markupId);

        if (!markupCc || !markupCc.tag) return { ruleItems: [], operators: [] };

        markupCc.select();
        await context.sync();

        return JSON.parse(markupCc.tag);
    }

    static async applyMarkup(context, { ruleItems, operators }, markupId = null) {
        let markupCc;

        if (markupId) {
            markupCc = await MarkupService.#getContentControlById(context, markupId);
        } else {
            const selection = context.document.getSelection();

            context.load(selection);
            await context.sync();
            markupCc = selection.insertContentControl();
        }

        const convertedRuleItems = convertRuleItems(ruleItems);

        if (!convertedRuleItems.length) {
            markupCc.delete(true);
            await context.sync();

            return null;
        }

        markupCc.title = MarkupService.CC_NAME;
        markupCc.tag = JSON.stringify({
            ruleItems : convertedRuleItems,
            operators
        });
        markupCc.cannotDelete = false;

        await context.sync();

        return markupCc.id;
    }

    static async editGeneratedDocument(context, ccIdsToRemove, filtersTree, setStep) {
        setStep(PROGRESS_KEY.DELETING_MARKUP);
        await MarkupService.#removeContentControlsByIds(context, ccIdsToRemove);
        await MarkupService.#removeAllContentControls(context);
        setStep(PROGRESS_KEY.REFERENCE_UPDATE);

        try {
            await MarkupService.#updateDocumentFieldsAndRefs(context);
        } catch (error) {
            setStep(PROGRESS_KEY.REFERENCE_UPDATE_ERROR);

            return;
        }

        setStep(PROGRESS_KEY.INSERTING_THE_FILTERS);
        await MarkupService.#insertSelectedFiltersList(context, filtersTree);
        setStep(PROGRESS_KEY.GENERATE_REFERENCES_FILE);
        await MarkupService.#generateAndLoadReferencesFile(context);
    }

    static async #generateAndLoadReferencesFile(context) {
        try {
            const referances = await getReferencesFromDocument(context);

            if (!referances.length) return;

            const { data } = await api.references.getReferencesFile(referances);

            loadReferencesFile(data.archiveName);
        } catch (error) {
            console.log(error);
        }
    }

    static async getContentControlIdsToRemove(context, filterSelections, existingFilters) {
        const selectedFilterIds = getSelectedIds(filterSelections);
        const ccs = await MarkupService
            .#getContentControlsByTitle(context, MarkupService.CC_NAME);
        const ruleEvaluator = new RuleEvaluator();
        const ids = [];

        for (const cc of ccs.items) {
            cc.load();
            await context.sync();

            if (!cc.tag) continue;

            const markup = JSON.parse(cc.tag);

            filterMarkup(markup, existingFilters);

            const { ruleItems, operators } = markup;

            if (ruleItems.length) {
                const result = ruleEvaluator.evaluateRule(
                    { ruleItems, operators },
                    new Set(selectedFilterIds)
                );

                if (!result) ids.push(cc.id);
            }
        }

        return ids;
    }

    static async isDigitizedDocument(context) {
        const ccs = context.document.contentControls.getByTitle(MarkupService.CC_NAME);

        context.load(ccs);
        await context.sync();

        return !!ccs.items.length;
    }
}
