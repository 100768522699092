import React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from '@fluentui/react';

const styles = {
    root : {
        backgroundColor : 'rgb(0, 120, 212)',
        boxShadow       : '3px 3px 4px rgb(0 0 0 / 5%), 0px 3px 4px rgb(0 0 0 / 7%)',
        borderRadius    : 2,
        minWidth        : 50
    }
};

export default function SupportButton(props) {
    const { onClick } = props;

    return (
        <PrimaryButton
            className='support-btn'
            iconProps={{ iconName: 'Mail' }}
            title='Support'
            ariaLabel='Contact support'
            styles={styles}
            onClick={onClick}
        />
    );
}

SupportButton.propTypes = {
    onClick : PropTypes.func.isRequired
};
