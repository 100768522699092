import { STRATEGIES, PRESELECT_CONFIG } from '../../constants/preselected';
import { STATE } from './filterStates';

export default class FilterSelector {
    #filters = {};

    constructor(filters, prevStates = {}) {
        for (const { id, parentId, value } of filters) {
            this.#filters[id] = {
                id,
                parentId,
                value,
                state    : STATE.UNCHECKED,
                disabled : false
            };
        }

        for (const { id, state, disabled } of Object.values(prevStates)) {
            if (state !== STATE.UNCHECKED) this.#filters[id].state = state;
            this.#filters[id].disabled = disabled;
        }
    }

    #getChildren(filterId) {
        return Object.values(this.#filters).filter(f => f.parentId === filterId);
    }

    #setChildrenState(filterId, newState) {
        const children = this.#getChildren(filterId);

        for (const child of children) {
            if (child.disabled) continue;

            this.#setFilterState(child.id, newState);
        }
    }

    #setParentsState(filterId) {
        const filter = this.#filters[filterId];
        const { parentId } = filter;

        if (parentId) {
            const parent = this.#filters[parentId];
            const parentChildren = this.#getChildren(parentId);
            const allChildrenChecked = parentChildren
                .every(child => child.state === STATE.CHECKED);
            const allChildrenUnchecked = parentChildren
                .every(child => child.state === STATE.UNCHECKED);

            if (allChildrenChecked) parent.state = STATE.CHECKED;
            else if (allChildrenUnchecked) parent.state = STATE.UNCHECKED;
            else parent.state = STATE.INTERMEDIATE;

            this.#setParentsState(parentId);
        }
    }

    #setFilterState(filterId, state) {
        this.#setChildrenState(filterId, state);

        const children = this.#getChildren(filterId);

        if (children?.length) {
            this.#setParentsState(children[0].id);
        } else {
            const filter = this.#filters[filterId];

            filter.state = state;
            this.#setParentsState(filter.id);
        }
    }

    #setChildrenDisabled(filterId, disabled) {
        const children = this.#getChildren(filterId);

        for (const child of children) {
            child.disabled = disabled;
            this.#setChildrenDisabled(child.id, disabled);
        }
    }

    #setParentsDisabled(filterId) {
        const filter = this.#filters[filterId];
        const { parentId } = filter;

        if (parentId) {
            const parent = this.#filters[parentId];
            const parentChildren = this.#getChildren(parentId);
            const allChildrenDisabled = parentChildren
                .every(child => child.disabled);

            if (allChildrenDisabled) parent.disabled = true;
            else parent.disabled = false;

            this.#setParentsDisabled(parentId);
        }
    }

    #setFilterDisabled(filterId, disabled) {
        const filter = this.#filters[filterId];

        filter.disabled = disabled;
        this.#setChildrenDisabled(filterId, filter.disabled);
        this.#setParentsDisabled(filterId);
    }

    #enableAllFilters() {
        for (const f of Object.values(this.#filters)) {
            this.#setFilterDisabled(f.id, false);
        }
    }

    #uncheckAllFilters() {
        for (const f of Object.values(this.#filters)) {
            this.#setFilterState(f.id, STATE.UNCHECKED);
        }
    }

    toggle(filterId) {
        const filter = this.#filters[filterId];

        if (filter.disabled) return;

        const newState = filter.state === STATE.CHECKED ? STATE.UNCHECKED : STATE.CHECKED;

        this.#setFilterState(filterId, newState);
    }

    toggleMultiple(filterIds) {
        for (const id of filterIds) {
            this.toggle(id);
        }
    }

    applyPreselectStrategy(strategy, filters = this.#filters, preselectConfig = PRESELECT_CONFIG) {
        if (strategy === STRATEGIES.NONE) {
            this.#enableAllFilters();

            return this.#uncheckAllFilters();
        }

        for (const f of Object.values(filters)) {
            const { value } = f;
            const configValue = preselectConfig[value.trim()];

            if (Array.isArray(configValue)) {
                const [ preselectStrategies, disableStrategies ] = configValue;

                if (disableStrategies && disableStrategies.includes(strategy)) {
                    this.#setFilterState(f.id, STATE.UNCHECKED);
                    this.#setFilterDisabled(f.id, true);
                } else {
                    const isPreselected = preselectStrategies.includes(strategy);
                    const filterState = isPreselected ? STATE.CHECKED : STATE.UNCHECKED;

                    this.#setFilterDisabled(f.id, false);
                    this.#setFilterState(f.id, filterState);
                }
            } else if (configValue) {
                this.applyPreselectStrategy(strategy, this.#getChildren(f.id), configValue);
            }
        }
    }

    getFilterState(filterId) {
        const { state, disabled } = this.#filters[filterId];

        return { state, disabled };
    }

    getState() {
        return Object.values(this.#filters);
    }

    getSelectedIds() {
        const selectedIds = [];

        for (const f of Object.values(this.#filters)) {
            const subfilters = this.#getChildren(f.id);

            if (!subfilters.length && f.state === STATE.CHECKED) {
                selectedIds.push(f.id);
            }
        }

        return selectedIds;
    }

    toString() {
        return JSON.stringify(this.getState());
    }
}
