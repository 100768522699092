import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@fluentui/react';

import { useStoreGenerate } from '../../contexts';

import { STATE } from '../../../lib/filter-selector/filterStates';

export default function FilterCheckbox(props) {
    const { filterId, filterSelector } = props;
    const { api } = useStoreGenerate();

    const { state: selectionState, disabled } = filterSelector.getFilterState(filterId);
    const checkboxStyles = { checkbox: { margin: 0 } };

    const handleCheck = useCallback(() => {
        filterSelector.toggle(filterId, true);
        api.setFilterSelections(filterSelector.getState());
    }, [ filterId, filterSelector ]);

    return (
        <Checkbox
            indeterminate={selectionState === STATE.INTERMEDIATE}
            checked={selectionState === STATE.CHECKED}
            disabled={disabled}
            styles={checkboxStyles}
            checkmarkIconProps={{
                iconName : selectionState === STATE.INTERMEDIATE ? 'Remove' : 'Accept'
            }}
            onChange={handleCheck}
        />
    );
}

FilterCheckbox.propTypes = {
    filterId       : PropTypes.number.isRequired,
    filterSelector : PropTypes.shape({
        getFilterState : PropTypes.func,
        getState       : PropTypes.func,
        toggle         : PropTypes.func
    }).isRequired
};
