import {
    LS_GENERATION_PARAMS_KEY,
    LS_SELECTED_FILTERS_KEY,
    PARENT_URL_SETTING
}                                 from '../../constants/meta';
import {
    NO_CUSTOM_XML_PART_FOUND_MESSAGE
}                                 from '../../constants/errors';
import api                        from '../../api/apiSingleton';
import MarkupService              from '../../lib/MarkupService';
import {
    hasPublicId,
    removePublicId,
    setDocumentPublicId,
    setPublicIdIfExistsByUrl
}                                 from './documentPublicId';
import { setAddinAutoshow }       from './addIn';
import { removeDocumentStatus }   from './documentStatus';

export async function prepareDocument() {
    const isPublicIdSet = await hasPublicId();

    if (!isPublicIdSet) {
        await setPublicIdIfExistsByUrl();
    }

    const { url } = Office.context.document;
    const documentSettings = Office.context.document.settings;

    documentSettings.set(PARENT_URL_SETTING, url);
    documentSettings.saveAsync();
    setAddinAutoshow();
}

export async function getDocumentByUrl() {
    const { url } = Office.context.document;

    if (url === '') throw new Error('Document has not been saved.');

    const { data } = await api.documents.showDocumentByUrl(url);

    return data;
}

export async function registerDocument() {
    const { data } = await api.documents.createDocument();

    return setDocumentPublicId(data.publicId);
}

export function saveDocument() {
    return Word.run(async (context) => {
        const thisDocument = context.document;

        context.load(thisDocument, 'saved');
        await context.sync();

        if (thisDocument.saved === false) {
            thisDocument.save();

            await context.sync();
            console.log('Saved the document.');
        } else {
            console.log('The document has not changed since the last save.');
        }
    });
}

export async function processGeneratedDocument(generationParams, setStep) {
    try {
        await removePublicId();
        await removeDocumentStatus();
    } catch (error) {
        if (error.message !== NO_CUSTOM_XML_PART_FOUND_MESSAGE) {
            throw error;
        }
    }

    const { contentControlIdsToRemove } = generationParams;
    const selectedFiltersTree = JSON.parse(localStorage.getItem(LS_SELECTED_FILTERS_KEY));

    localStorage.removeItem(LS_GENERATION_PARAMS_KEY);
    localStorage.removeItem(LS_SELECTED_FILTERS_KEY);

    return Word.run(async (context) => {
        await MarkupService.editGeneratedDocument(
            context,
            contentControlIdsToRemove,
            selectedFiltersTree,
            setStep
        );
    });
}
