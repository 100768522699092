/* eslint-disable react/no-multi-comp */

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@fluentui/react';

import FilterCheckbox from './FilterCheckbox';

import './MarkupList.css';

function MarkupListItem(props) {
    const { filter, depth, selectedRule, filterSelector } = props;
    const [ showSubfilters, setShowSubfilters ] = useState(true);

    const labelStyle = !depth ? { fontWeight: 600 } : null;

    const handleExpand = useCallback(
        () => setShowSubfilters(!showSubfilters),
        [ showSubfilters ]
    );

    return (
        <>
            <div className='markup-list-item'>
                <FilterCheckbox
                    selectedRule={selectedRule}
                    filterId={filter.id}
                    filterSelector={filterSelector}
                />
                <div className='markup-label-wrapper'>
                    {!!filter.subfilters.length && (
                        <Icon
                            iconName={`Chevron${showSubfilters ? 'Down' : 'Right'}`}
                            onClick={handleExpand}
                        />
                    )}
                    <div className='markup-label' style={labelStyle}>
                        {filter.value}
                    </div>
                </div>
            </div>
            {showSubfilters && (
                <MarkupList
                    filters={filter.subfilters}
                    depth={depth + 1}
                    selectedRule={selectedRule}
                    filterSelector={filterSelector}
                />
            )}
        </>
    );
}

export default function MarkupList(props) {
    const { filters, depth, selectedRule, filterSelector } = props;

    return (
        <ul className='markup-list'>
            {filters.map(filter => (
                <li key={filter.id}>
                    <MarkupListItem
                        key={filter.id}
                        filter={filter}
                        depth={depth}
                        selectedRule={selectedRule}
                        filterSelector={filterSelector}
                    />
                </li>
            ))}
        </ul>
    );
}

MarkupListItem.propTypes = {
    filter : PropTypes.shape({
        id         : PropTypes.number.isRequired,
        value      : PropTypes.string.isRequired,
        subfilters : PropTypes.array.isRequired
    }).isRequired,
    depth          : PropTypes.number.isRequired,
    selectedRule   : PropTypes.number.isRequired,
    filterSelector : PropTypes.object.isRequired
};

MarkupList.propTypes = {
    filters : PropTypes.arrayOf(PropTypes.shape({
        id : PropTypes.number.isRequired
    })).isRequired,
    depth          : PropTypes.number.isRequired,
    selectedRule   : PropTypes.number.isRequired,
    filterSelector : PropTypes.object.isRequired
};
