import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DefaultButton, PrimaryButton } from '@fluentui/react';

import {
    ERROR_CODE_TO_MESSAGE,
    DEFAULT_ERROR_MESSAGE
} from '../../../constants/errors';

import { useStoreFilter } from '../../contexts';
import FilterComponentField from './FilterComponentField';
import FilterDropdownMenu from './FilterDropdownMenu';

import './FilterComponent.css';

async function editFilter(newFilterValue, filter, api) {
    if (newFilterValue !== filter.value) {
        try {
            await api.editFilter(newFilterValue, filter.id);
        } catch (error) {
            api.setError(ERROR_CODE_TO_MESSAGE[error.code] || DEFAULT_ERROR_MESSAGE);

            return;
        }
    }
}

export default function FilterComponent(props) {
    const { filter, depth, editingEnabled, setEditingEnabled } = props;
    const { api } = useStoreFilter();
    const [ filterValue, setFilterValue ] = useState(filter.value);
    const [ isDropdownShown, setIsDropdownShown ] = useState(false);

    useEffect(() => setFilterValue(!editingEnabled ? filter.value : filterValue));

    const handleFilterEdit = useCallback(async (e) => {
        e.preventDefault();
        await editFilter(filterValue, filter, api);
        handleCancelEdit();
    }, [ filterValue, filter ]);

    const handleEnableEdit = useCallback(() => {
        api.setSelectedFilterId(filter.id);
        setEditingEnabled(true);
    }, [ filter ]);

    const handleCancelEdit = useCallback(() => {
        api.setSelectedFilterId(null);
        setEditingEnabled(false);
        setIsDropdownShown(false);
    }, []);

    const handleFilterDelete = useCallback(() => {
        api.deleteFilter(filter.id);
    }, [ filter ]);

    const handleHover = useCallback((e) => {
        if (e.type === 'mouseenter') setIsDropdownShown(true);
        else setIsDropdownShown(false);
    }, []);

    return (
        <form
            className='filter-input-form'
            onSubmit={handleFilterEdit}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
        >
            <div className='filter'>
                <FilterComponentField
                    filter={filter}
                    depth={depth}
                    value={filterValue}
                    editingEnabled={editingEnabled}
                    setFilterValue={setFilterValue}
                />
                {(isDropdownShown || editingEnabled) && (
                    <FilterDropdownMenu
                        editingEnabled={editingEnabled}
                        enableEditing={handleEnableEdit}
                        deleteFilter={handleFilterDelete}
                    />
                )}
            </div>
            {(editingEnabled && filterValue) && (
                <div className='edit-controls'>
                    <DefaultButton
                        className='filter-btn cancel-edit-btn'
                        onClick={handleCancelEdit}
                    >
                        Cancel
                    </DefaultButton>
                    <PrimaryButton
                        className='filter-btn save-filter-btn'
                        type='submit'
                    >
                        Save
                    </PrimaryButton>
                </div>
            )}
        </form>
    );
}

FilterComponent.propTypes = {
    filter : PropTypes.shape({
        id    : PropTypes.number.isRequired,
        value : PropTypes.string.isRequired
    }).isRequired,
    depth             : PropTypes.number.isRequired,
    editingEnabled    : PropTypes.bool.isRequired,
    setEditingEnabled : PropTypes.func.isRequired
};
