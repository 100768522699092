export const PROGRESS_KEY = {
    DELETING_MARKUP          : 'DELETING_MARKUP',
    REFERENCE_UPDATE         : 'REFERENCE_UPDATE',
    REFERENCE_UPDATE_ERROR   : 'REFERENCE_UPDATE_ERROR',
    INSERTING_THE_FILTERS    : 'INSERTING_THE_FILTERS',
    DEFAULT                  : 'DEFAULT',
    GENERATE_REFERENCES_FILE : 'GENERATE_REFERENCES_FILE'
};

export const PROGRESS_TEXT = {
    [PROGRESS_KEY.DELETING_MARKUP]          : 'Generating GRD based on the selected filters.',
    [PROGRESS_KEY.INSERTING_THE_FILTERS]    : 'Finalizing the document.',
    [PROGRESS_KEY.REFERENCE_UPDATE]         : 'Updating output references database.',
    [PROGRESS_KEY.GENERATE_REFERENCES_FILE] : 'Generating references file.',
    [PROGRESS_KEY.DEFAULT]                  : 'Please, wait until your add-in is ready.'
};

export const PROGRESS_TITLE = 'iGRD Add-in';
