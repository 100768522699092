import React, { useEffect, useState } from 'react';

import COLORS from '../../../constants/colors';
import FilterSelector from '../../../lib/filter-selector/FilterSelector';
import { createFiltersTree } from '../../../lib/utils/filters';

import { useStoreFilter, useStoreMarkup } from '../../contexts';
import AddRuleButton from './AddRuleButton';
import MarkupTips from './MarkupTips';
import RulesChain from './RulesChain';
import MarkupList from './MarkupList';

import '../main/Main.css';
import './MarkupPage.css';

export default function MarkupPage() {
    const { filters } = useStoreFilter();
    const { currentMarkupId, ruleItems } = useStoreMarkup();
    const [ selectedRule, setSelectedRule ] = useState(null);

    useEffect(() => {
        if (selectedRule === null) {
            setSelectedRule((currentMarkupId && ruleItems.length) ? 0 : null);
        }
    }, [ ruleItems ]);

    useEffect(() => {
        setSelectedRule((currentMarkupId && ruleItems.length) ? 0 : null);
    }, [ currentMarkupId ]);

    const filtersTree = createFiltersTree(filters);
    const filterSelector = new FilterSelector(
        filters,
        selectedRule !== null ? ruleItems[selectedRule]?.filterSelections : []
    );

    filtersTree.reverse();

    return (
        <div className='ms-Grid page-container'>
            <div id='markup-header'>
                <MarkupTips />
                <AddRuleButton />
            </div>
            <RulesChain selectedRule={selectedRule} setSelectedRule={setSelectedRule} />
            {selectedRule !== null && filtersTree.map((filter, i) => (
                <div
                    key={filter.id}
                    className='markup-filter-panel'
                    style={{ backgroundColor: COLORS[i % COLORS.length] }}
                >
                    <MarkupList
                        filters={[ filter ]}
                        depth={0}
                        selectedRule={selectedRule}
                        filterSelector={filterSelector}
                    />
                </div>
            ))}
        </div>
    );
}
