/* eslint-disable react/no-multi-comp */

import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontIcon } from '@fluentui/react';

import { DEPTH_LIMIT } from '../../../constants/filters';

import { useStoreFilter } from '../../contexts';
import FilterComponent from './FilterComponent';
import FilterForm from './FilterForm';

import './FilterList.css';

function FilterListItemMarker() {
    return (
        <FontIcon
            iconName='RadioBullet'
            className='filter-list-item-marker'
        />
    );
}

function FilterListItem(props) {
    const { filter, depth } = props;
    const { error, selectedFilterId } = useStoreFilter();
    const [ editingEnabled, setEditingEnabled ] = useState(false);

    useEffect(() => setEditingEnabled(editingEnabled && isSelected));

    const isSelected = filter.id === selectedFilterId;
    const classNames = [
        depth === 0 && 'root-filter',
        (isSelected && !editingEnabled) && 'selected-filter'
    ].filter(name => !!name).join(' ');
    const isSingleRoot = depth === 0 && !filter.subfilters.length;
    const itemWrapperStyle = isSingleRoot ? { paddingBottom: '8px' } : null;

    return (
        <div className={classNames || null}>
            <div
                className={`list-item-wrapper${editingEnabled ? ' edited-filter' : ''}`}
                style={itemWrapperStyle}
            >
                {(!!error && editingEnabled) && <div className='error-message'>{error}</div>}
                <div>
                    {depth !== 0 && <FilterListItemMarker />}
                    <FilterComponent
                        filter={filter}
                        depth={depth}
                        editingEnabled={editingEnabled}
                        setEditingEnabled={setEditingEnabled}
                    />
                </div>
            </div>
            {depth < DEPTH_LIMIT && (
                <FilterList
                    filters={filter.subfilters}
                    depth={depth + 1}
                    isSelected={isSelected}
                    editingEnabled={editingEnabled}
                />
            )}
        </div>
    );
}

export default function FilterList(props) {
    const {
        filters,
        depth,
        isSelected,
        editingEnabled
    } = props;
    const { selectedFilterId, error } = useStoreFilter();
    const [ showInput, setShowInput ] = useState(false);

    useEffect(() => setShowInput(isSelected && !editingEnabled));

    const handleClose = useCallback(() => setShowInput(false), []);

    return (depth > 1 || filters.length > 0 || showInput) && (
        <ul className='filter-list'>
            {
                filters.map(filter => (
                    <li key={filter.id}>
                        <FilterListItem key={filter.id} filter={filter} depth={depth} />
                    </li>
                ))
            }
            {showInput && (
                <li>
                    {!!error && <div className='error-message'>{error}</div>}
                    <div className='new-subfilter'>
                        <FilterListItemMarker />
                        <FilterForm
                            parentId={selectedFilterId}
                            handleClose={handleClose}
                        />
                    </div>
                </li>
            )}
        </ul>
    );
}

FilterListItem.propTypes = {
    filter : PropTypes.shape({
        id         : PropTypes.number.isRequired,
        value      : PropTypes.string.isRequired,
        subfilters : PropTypes.array.isRequired
    }).isRequired,
    depth : PropTypes.number.isRequired
};

FilterList.propTypes = {
    filters : PropTypes.arrayOf(PropTypes.shape({
        id    : PropTypes.number.isRequired,
        value : PropTypes.string.isRequired
    })).isRequired,
    depth          : PropTypes.number.isRequired,
    isSelected     : PropTypes.bool,
    editingEnabled : PropTypes.bool
};

FilterList.defaultProps = {
    isSelected     : false,
    editingEnabled : false
};
