import React, { useEffect, useState }                     from 'react';
import { createTheme, ThemeProvider }                     from '@fluentui/react';
import { initializeIcons }                                from '@fluentui/font-icons-mdl2';
import { HashRouter, Route, Routes }                      from 'react-router-dom';

import { AuthContextProvider }                            from '../contexts/authContext';

import { LS_GENERATION_PARAMS_KEY, PARENT_URL_SETTING }   from '../../constants/meta';
import { PROGRESS_KEY, PROGRESS_TITLE }                   from '../../constants/progress';

import { prepareDocument, processGeneratedDocument }      from '../../lib/utils/document';

import WelcomePage                                        from './welcome/WelcomePage';
import Main                                               from './main/Main';

const appTheme = createTheme({
    defaultFontStyle : {
        fontFamily : 'Open Sans',
        fontWeight : 'regular'
    },
    palette : {
        neutralPrimary : '#000'
    }
});

initializeIcons();

export default function App() {
    const [ isOfficeInitialized, setIsOfficeInitialized ]   = useState(false);
    const [ messageStep, setMessageStep ]                   = useState(PROGRESS_KEY.DEFAULT);

    useEffect(() => {
        Office.onReady(async () => {
            const { url } = Office.context.document;
            const documentSettings = Office.context.document.settings;
            const parentUrl = documentSettings.get(PARENT_URL_SETTING);
            const generationParams = JSON.parse(localStorage.getItem(LS_GENERATION_PARAMS_KEY));
            const isDocumentGenerated = parentUrl && parentUrl !== url && parentUrl === generationParams?.parentUrl;

            if (isDocumentGenerated) {
                await processGeneratedDocument(generationParams, setMessageStep);
            }

            await prepareDocument();

            setIsOfficeInitialized(true);
        });
    }, []);

    return (
        <React.StrictMode>
            <ThemeProvider theme={appTheme}>
                <AuthContextProvider>
                    <HashRouter>
                        <Routes>
                            <Route
                                path    = '/'
                                element = {
                                    <WelcomePage
                                        title               = {PROGRESS_TITLE}
                                        isOfficeInitialized = {isOfficeInitialized}
                                        setMessageStep      = {setMessageStep}
                                        messageStep         = {messageStep}
                                    />
                                }
                            />
                            <Route
                                path    = '/main'
                                element = {<Main />}
                            />
                        </Routes>
                    </HashRouter>
                </AuthContextProvider>
            </ThemeProvider>
        </React.StrictMode>
    );
}
