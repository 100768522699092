import React from 'react';
import { DefaultButton } from '@fluentui/react';

import { useStoreFilter, useStoreMarkup } from '../../contexts';

import './MarkupPage.css';

export default function AddRuleButton() {
    const { api } = useStoreMarkup();
    const { filters } = useStoreFilter();

    const itemStyle = { textAlign: 'center', margin: 0, padding: 0 };
    const menuProps = {
        items : [
            { key: 'all', text: 'All', style: itemStyle, onClick: () => api.addRuleItem('All') },
            { key: 'any', text: 'Any', style: itemStyle, onClick: () => api.addRuleItem('Any') }
        ],
        isBeakVisible : false,
        styles        : {
            container : { width: '80px', margin: 0 },
            list      : { width: '80px' }
        }
    };

    return (
        <DefaultButton
            className='rule-btn'
            text='Rule'
            menuProps={menuProps}
            disabled={!filters?.length}
            menuIconProps={{ style: { display: 'none', padding: 0 } }}
            iconProps={{ iconName: 'Add', style: { color: '#005FB8' } }}
        />
    );
}
