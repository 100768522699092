import queryString          from 'query-string';
import { SESSION_ID_KEY }   from '../constants/auth';

export default class ApiClient {
    constructor({ apiPrefix, apiUrl } = {}) {
        this.apiPrefix = apiPrefix;
        this.apiUrl = apiUrl;
    }

    async request({ endpoint, method, params = {}, headers = {}, body, asJson = true }) {
        const sessionId = localStorage.getItem(SESSION_ID_KEY);
        const head = {
            ...headers,
            'X-Session-Id' : sessionId
        };
        const asFormData = !asJson;

        const query = Object.keys(params).length ? `?${queryString.stringify(params)}` : '';
        const url = `${this.apiUrl}${this.apiPrefix}/${endpoint}${query}`;

        try {
            let payload = body;

            if (asJson && !asFormData) {
                payload = JSON.stringify(body);
                head['Content-Type'] = 'application/json';
            }

            if (asFormData) {
                payload = body;
            }

            const response = await fetch(url, {
                method,
                headers : head,
                body    : payload
            });
            const BAD_REQUEST_STATUS = 400;

            if (response.status >= BAD_REQUEST_STATUS) {
                throw new Error('Bad response from server');
            }

            const data = await response.json();

            if (data && data.status === 1) {
                return data;
            }

            throw data.error;
        } catch (err) {
            console.warn('Unhandled exeption');
            console.warn(err);

            throw err;
        }
    }

    get({ endpoint, params, headers, asJson = true }) {
        return this.request({ endpoint, method: 'GET', params, headers, asJson });
    }

    post({ endpoint, body = {}, headers = undefined, asJson }) {
        return this.request({ endpoint, method: 'POST', body, headers, asJson });
    }

    patch({ endpoint, body = {}, headers = undefined, asJson }) {
        return this.request({ endpoint, method: 'PATCH', body, headers, asJson });
    }

    put({ endpoint, body = {}, headers = undefined, asJson = true }) {
        return this.request({ endpoint, method: 'PUT', body, headers, asJson });
    }

    delete({ endpoint, body = {}, headers, asJson = true }) {
        return this.request({ endpoint,  method: 'DELETE', body, headers, asJson });
    }
}
