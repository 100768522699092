import { URL } from '../constants/api';
import Base    from './Base';

export default class Filters extends Base {
    getFiltersList = async (documentPublicId) => {
        const response = await this.apiClient.get({
            endpoint : URL.FILTERS,
            params   : { documentPublicId }
        });

        return {
            filters : response.data
        };
    }

    createFilter = async (body) => {
        const response = await this.apiClient.post({ endpoint: URL.FILTERS, body });

        return response;
    }

    deleteFilter = async (id, body) => {
        const response = await this.apiClient.delete({
            endpoint : `${URL.FILTERS}/${id}`,
            body
        });

        return response;
    }

    updateFilter = async (body, id) => {
        const response = await this.apiClient.put({ endpoint: `${URL.FILTERS}/${id}`, body });

        return response;
    }
}
