import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

const GenerateContext = React.createContext();

export function useStoreGenerate() {
    return useContext(GenerateContext);
}

export function GenerateContextProvider({ children }) {
    const [ filterSelections, setFilterSelections ] = useState([]);

    return (
        <GenerateContext.Provider
            value={{
                filterSelections,
                api : {
                    setFilterSelections
                }
            }}
        >
            {children}
        </GenerateContext.Provider>
    );
}

GenerateContextProvider.propTypes = {
    children : PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};
