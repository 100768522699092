/* eslint-disable react/no-multi-comp */

import React from 'react';
import PropTypes from 'prop-types';
import { DirectionalHint, TeachingBubble } from '@fluentui/react';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import { useBoolean } from '@fluentui/react-hooks';

function RuleTeachingBubble(props) {
    const { buttonId, children, handleDismiss } = props;
    const bubbleStyles = {
        body        : { ':not(:last-child)': { marginBottom: 0 } },
        bodyContent : { padding: '10px 20px', marginBottom: 0 },
        closeButton : { margin: 0 }
    };

    return (
        <TeachingBubble
            calloutProps={{
                directionalHint : DirectionalHint.bottomCenter,
                className       : 'custom-tooltip'
            }}
            target={`#${buttonId}`}
            hasCloseButton
            closeButtonAriaLabel='Close'
            onDismiss={handleDismiss}
            styles={bubbleStyles}
        >
            {children}
        </TeachingBubble>
    );
}

export function Tooltip(props) {
    const { id, title, children } = props;
    const [ tipVisible, { toggle: toggleTipVisible } ] = useBoolean(false);

    return (
        <>
            <span id={id} className='rule-tip' onClick={toggleTipVisible}>
                { children }
            </span>
            {
                tipVisible ? (
                    <RuleTeachingBubble buttonId={id} handleDismiss={toggleTipVisible}>
                        {title}
                    </RuleTeachingBubble>
                ) : null
            }
        </>
    );
}

RuleTeachingBubble.propTypes = {
    buttonId      : PropTypes.string.isRequired,
    handleDismiss : PropTypes.func.isRequired,
    children      : PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

Tooltip.propTypes = {
    id    : PropTypes.string.isRequired,
    title : PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    children : PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};
