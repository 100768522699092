import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { NO_CUSTOM_XML_PART_FOUND_MESSAGE } from '../../constants/errors';
import api from '../../api/apiSingleton';
import { getDocumentPublicId, getDocumentPublicIdIfExists } from '../../lib/utils/documentPublicId';
import { registerDocument } from '../../lib/utils/document';

const FiltersContext = React.createContext();

export function useStoreFilter() {
    return useContext(FiltersContext);
}

async function refreshFilters(setState) {
    try {
        const documentPublicId = await getDocumentPublicId();
        const data = await api.filters.getFiltersList(documentPublicId);

        setState((prev) => ({ ...prev, filters: [ ...data.filters ] }));
    } catch (error) {
        console.log(error);
    }
}

async function createFilter(setState, { parentId = null, value }) {
    let documentPublicId;

    try {
        documentPublicId = await getDocumentPublicId();
    } catch (error) {
        if (error.message === NO_CUSTOM_XML_PART_FOUND_MESSAGE) {
            documentPublicId = await registerDocument();
        } else {
            console.log(error);

            throw error;
        }
    }

    const data = { documentPublicId, parentId, value };
    const result = await api.filters.createFilter({ data });

    if (!result.status) throw new Error(result.code);

    const filter = result.data;

    await refreshFilters(setState);

    return filter;
}

async function editFilter(setState, value, id) {
    const documentPublicId = await getDocumentPublicIdIfExists();
    const data = { value, documentPublicId };
    const result = await api.filters.updateFilter({ data }, id);

    if (!result.status) throw new Error(result.code);

    return refreshFilters(setState);
}

async function deleteFilter(setState, id) {
    const documentPublicId = await getDocumentPublicIdIfExists();

    await api.filters.deleteFilter(id, { data: { documentPublicId } });

    return refreshFilters(setState);
}

async function setSelectedFilterId(setState, id) {
    setState((prev) => ({
        ...prev,
        selectedFilterId : id
    }));
}

async function setError(setState, errorMessage) {
    setState((prev) => ({ ...prev, error: errorMessage }));
}

export function FiltersContextProvider({ children }) {
    const [ state, setState ] = useState({
        filters          : [],
        selectedFilterId : null,
        error            : null
    });

    useEffect(() => {
        refreshFilters(setState);
    }, []);

    useEffect(() => {
        if (state.selectedFilterId) {
            setError(setState, '');
        }

        return () => setError(setState, '');
    }, [ state.selectedFilterId ]);

    return (
        <FiltersContext.Provider
            value={{
                filters          : state.filters,
                selectedFilterId : state.selectedFilterId,
                error            : state.error,
                api              : {
                    refreshFilters      : refreshFilters.bind(null, setState),
                    createFilter        : createFilter.bind(null, setState),
                    editFilter          : editFilter.bind(null, setState),
                    deleteFilter        : deleteFilter.bind(null, setState),
                    setSelectedFilterId : setSelectedFilterId.bind(null, setState),
                    setError            : setError.bind(null, setState)
                }
            }}
        >
            { children }
        </FiltersContext.Provider>
    );
}

FiltersContextProvider.propTypes = {
    children : PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};
