import React, { useState }   from 'react';
import { Pivot, PivotItem } from '@fluentui/react';
import { registerIcons } from '@fluentui/react/lib/Styling';
import { Icon } from '@fluentui/react/lib/Icon';

import { useNavigate } from 'react-router-dom';
import {
    FiltersContextProvider,
    MarkupContextProvider,
    GenerateContextProvider
} from '../../contexts';
import Generate from '../generate/GeneratePage';
import CreateFiltersPage from '../create-filters/CreateFiltersPage';
import Markup from '../markup/MarkupPage';
import Version from '../Version';
// import { Digitized } from '../digitized/Digitized';
import { Logout } from '../logout/Logout';

import { useStoreAuth } from '../../contexts/authContext';

import './Main.css';

registerIcons({
    icons : {
        'create-filters-tab-icon' : (
            <img src='assets/create-filters-tab-icon.svg' className='tab-icon' alt='' />
        ),
        'markup-tab-icon' : (
            <img src='assets/markup-tab-icon.svg' className='tab-icon' alt='' />
        ),
        'generate-tab-icon' : (
            <img src='assets/generate-tab-icon.svg' className='tab-icon' alt='' />
        ),
        'create-filters-tab-icon-selected' : (
            <img src='assets/create-filters-tab-icon-selected.svg' className='tab-icon' alt='' />
        ),
        'markup-tab-icon-selected' : (
            <img src='assets/markup-tab-icon-selected.svg' className='tab-icon' alt='' />
        ),
        'generate-tab-icon-selected' : (
            <img src='assets/generate-tab-icon-selected.svg' className='tab-icon' alt='' />
        ),
        'icon-info' : (
            <img src='assets/info.svg' className='tab-icon' alt='' />
        )
    }
});

const pivotStyles = {
    link : {
        fontSize   : '12px',
        fontWeight : 600
    },
    linkIsSelected : {
        ':before' : {
            zIndex          : 100,
            bottom          : '-3px',
            height          : '3px',
            backgroundColor : '#005FB8'
        }
    }
};

const pivotItemsForAdmin = [
    {
        headerText   : 'Create filters',
        child        : <CreateFiltersPage />,
        icon         : 'create-filters-tab-icon',
        iconSelected : 'create-filters-tab-icon-selected',
        iconInfo     : 'icon-info'
    },
    {
        headerText : 'Markup',
        child      : (
            <MarkupContextProvider>
                <Markup />
            </MarkupContextProvider>
        ),
        icon         : 'markup-tab-icon',
        iconSelected : 'markup-tab-icon-selected'
    }
];

const pivotItemsCommon = [
    {
        headerText : 'Generate',
        child      : (
            <GenerateContextProvider>
                <Generate />
            </GenerateContextProvider>
        ),
        icon         : 'generate-tab-icon',
        iconSelected : 'generate-tab-icon-selected'
    }
];

function renderDigitizedIcon(item, selectedKey) {
    return (
        <span key={item.headerText}>
            <Icon iconName={item.headerText === selectedKey ? item.iconSelected : item.icon} />
            { item.headerText }
            {/* <Digitized icon={item.iconInfo} /> */}
        </span>
    );
}

export default function Main() {
    const { profile, api } = useStoreAuth();
    const navigate = useNavigate();

    const [ selectedKey, setSelectedKey ] = useState('Create filters');
    const pivotItems = profile?.isAdmin ? [ ...pivotItemsForAdmin, ...pivotItemsCommon ] : pivotItemsCommon;

    const renderedPivotItems = pivotItems.map((item, i) => {
        return (
            <PivotItem
                key                 = {i} /* eslint-disable-line react/no-array-index-key */
                itemKey             = {item.headerText}
                itemIcon            = {item.headerText === selectedKey ? item.iconSelected : item.icon}
                /* eslint-disable-next-line react/jsx-no-bind */
                onRenderItemLink    = {() => renderDigitizedIcon(item, selectedKey)}
            >
                {item.child}
            </PivotItem>
        );
    });

    async function logout() {
        const response = await api.logout();

        if (response?.status) {
            navigate('/', { replace: true });
        }
    }

    function handleLinkClick(item) {
        setSelectedKey(item.props.itemKey);
    }

    return (
        <div className='container'>
            <FiltersContextProvider>
                <Pivot
                    className   = 'navigation'
                    selectedKey = {selectedKey}
                    styles      = {pivotStyles}
                    onLinkClick = {handleLinkClick}
                >
                    {renderedPivotItems}
                </Pivot>
            </FiltersContextProvider>
            <div className='footer'>
                <Version />
                <Logout action={logout} {...profile} />
            </div>
        </div>
    );
}
