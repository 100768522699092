import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, ResponsiveMode } from '@fluentui/react';

import { ISSUE_TYPES } from '../../../constants/feedback';

const dropdownStyles = {
    title : {
        borderRadius : 0
    },
    dropdown : {
        minWidth  : 150,
        boxShadow : '3px 3px 4px 0 rgb(0 0 0 / 5%)'
    }
};

const options = [
    { key: ISSUE_TYPES.CONTENT, text: ISSUE_TYPES.CONTENT },
    { key: ISSUE_TYPES.TECH, text: ISSUE_TYPES.TECH },
    { key: ISSUE_TYPES.OTHER, text: ISSUE_TYPES.OTHER }
];

export default function FeedbackTypeDropdown(props) {
    const { onChange } = props;
    const handleTypeSelect = useCallback((e, o) => onChange(o.text), []);

    return (
        <Dropdown
            defaultSelectedKey={ISSUE_TYPES.CONTENT}
            options={options}
            styles={dropdownStyles}
            responsiveMode={ResponsiveMode.large}
            onChange={handleTypeSelect}
        />
    );
}

FeedbackTypeDropdown.propTypes = {
    onChange : PropTypes.func.isRequired
};
