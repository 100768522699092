const PREP = 'PrEP';
const OP = 'Outpatient';
const NONE = 'No strategy';

const STRATEGIES = { PREP, OP, NONE };

const PRESELECT_CONFIG = {
    'Drug' : {
        'Evusheld' : [ [ PREP, OP ] ]
    },
    'Indication' : {
        'PrEP' : [ [ PREP ] ]
    },
    'Age' : {
        'Adolescent and adults (12+ years)' : [ [ PREP, OP ] ]
    },
    'Population' : {
        'Vaccine contraindicated population' : [ [ PREP, OP ] ],
        'All immunocompromised populations'  : [ [ PREP, OP ] ],
        'High risk categories'               : [ [ OP ] ]
    },
    'Regulatory perspective' : {
        'EMA' : [ [ PREP, OP ] ]
    },
    'Payer restriction' : {
        'No restriction'              : [ [ PREP, OP ] ],
        'Restricted to patients with' : {
            'Cancer (active)'              : [ [], [ OP ] ],
            'Blood cancer'                 : [ [], [ OP ] ],
            'Immunosuppressant medication' : [ [], [ OP ] ],
            'CAR-T or HSCT'                : [ [], [ OP ] ],
            'Kidney disease'               : [ [], [ OP ] ],
            'Uncontrolled/advanced HIV'    : [ [], [ OP ] ],
            'Primary immunodeficiency'     : [ [], [ OP ] ],
            'Solid organ transplant'       : [ [], [ OP ] ]
        },
        'Restricted to patients with:' : {
            'Contraindicated to Paxlovid'          : [ [], [ PREP ] ],
            'Renal disease'                        : [ [], [ PREP ] ],
            'Liver disease'                        : [ [], [ PREP ] ],
            'Comorbidities (diabetes, asthma etc)' : [ [], [ PREP ] ]
        }
    },
    'Comparator' : {
        'Standard of care'           : [ [ PREP ] ],
        'All mAbs (Outpatient only)' : {
            'Sotrovimab'   : [ [ OP ], [ PREP ] ],
            'Regeneron'    : [ [ OP ], [ PREP ] ],
            'Bebtelovimab' : [ [ OP ], [ PREP ] ]
        },
        'All antivirals (Outpatient only)' : {
            'Paxlovid'     : [ [ OP ], [ PREP ] ],
            'Remdesivir'   : [ [ OP ], [ PREP ] ],
            'Molnupiravir' : [ [ OP ], [ PREP ] ]
        }
    },
    'Section' : {
        '1. Burden of disease'   : [ [ PREP, OP ] ],
        '2. Product information' : [ [ PREP, OP ] ],
        '3. Clinical value'      : [ [ PREP, OP ] ],
        '4. Economic value'      : [ [ PREP, OP ] ]
    }
};

export {
    STRATEGIES,
    PRESELECT_CONFIG
};
