import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import MarkupService from '../../lib/MarkupService';
import FilterSelector from '../../lib/filter-selector/FilterSelector';
import { filterMarkup } from '../../lib/utils/markup';

import { useStoreFilter } from './filtersContext';

const MarkupContext = createContext();

export function useStoreMarkup() {
    return useContext(MarkupContext);
}

function updateMarkup(currentMarkupId, setCurrentMarkupId) {
    Word.run(async (context) => {
        const markupId = await MarkupService
            .getCurrentContentControlId(context);

        if (markupId !== currentMarkupId) {
            setCurrentMarkupId(markupId);
        }
    });
}

function applyMarkup(state, currentMarkupId, setCurrentMarkupId, markup) {
    const markupToApply = {
        ruleItems : markup.ruleItems || state.ruleItems,
        operators : markup.operators || state.operators
    };

    Word.run(async (context) => {
        const modifiedMarkupId = await MarkupService
            .applyMarkup(context, markupToApply, currentMarkupId);

        if (modifiedMarkupId !== currentMarkupId) {
            setCurrentMarkupId(modifiedMarkupId);
        }
    });
}

function getMarkup(setState, filters, markupId) {
    setState({ ruleItems: [], operators: [] });

    Word.run(async (context) => {
        const markup = await MarkupService.getMarkup(context, markupId);

        if (!markup.ruleItems.length) return;

        const ruleItems = [];
        const markupChanged = filterMarkup(markup, filters);

        for (const item of markup.ruleItems) {
            const filterSelector = new FilterSelector(filters);
            const [ type, selectedIds ] = item;

            filterSelector.toggleMultiple(selectedIds);
            ruleItems.push({ type, filterSelections: filterSelector.getState() });
        }

        const convertedMarkup = { ruleItems, operators: markup.operators };

        if (markupChanged) {
            await MarkupService.applyMarkup(context, convertedMarkup, markupId);
        }

        setState(convertedMarkup);
    });
}

function addRuleItem(state, setState, type) {
    if (state.ruleItems.length) {
        setState((prev) => ({
            ...prev,
            operators : [ ...prev.operators, 'or' ]
        }));
    }

    setState((prev) => ({
        ...prev,
        ruleItems : [ ...prev.ruleItems, { type, filterSelections: new Set() } ]
    }));
}

function removeRuleItem(state, setState, applyMarkupInContext, id) {
    const updatedState = {
        ruleItems : state.ruleItems.filter((item, i) => i !== id),
        operators : state.operators.filter((item, i) => i !== id - 1)
    };

    setState(updatedState);
    applyMarkupInContext(updatedState);
}

function toggleOperator(state, setState, applyMarkupInContext, id) {
    const modifiedOperators = [ ...state.operators ];

    modifiedOperators[id] = modifiedOperators[id] === 'or' ? 'and' : 'or';
    setState((prev) => ({ ...prev, operators: modifiedOperators }));
    applyMarkupInContext({ operators: modifiedOperators });
}

function selectFilters(state, setState, applyMarkupInContext, selectedRule, filterStates) {
    const modifiedRules = [ ...state.ruleItems ];
    const rule = modifiedRules[selectedRule];

    rule.filterSelections = filterStates;
    setState((prev) => ({ ...prev, ruleItems: modifiedRules }));
    applyMarkupInContext({ ruleItems: modifiedRules });
}

export function MarkupContextProvider({ children }) {
    const { filters } = useStoreFilter();
    const [ state, setState ] = useState({
        ruleItems : [],
        operators : []
    });
    const [ currentMarkupId, setCurrentMarkupId ] = useState(null);

    const applyMarkupInContext = applyMarkup
        .bind(null, state, currentMarkupId, setCurrentMarkupId);
    const getMarkupInContext = getMarkup
        .bind(null, setState, filters);

    useEffect(() => {
        getMarkupInContext(currentMarkupId);
        Office.context.document.addHandlerAsync(
            Office.EventType.DocumentSelectionChanged,
            () => updateMarkup(currentMarkupId, setCurrentMarkupId)
        );
    }, [ currentMarkupId ]);

    return (
        <MarkupContext.Provider
            value={{
                currentMarkupId,
                ruleItems : state.ruleItems,
                operators : state.operators,
                api       : {
                    getCurrentMarkup : getMarkupInContext,
                    addRuleItem      : addRuleItem.bind(null, state, setState),
                    removeRuleItem   : removeRuleItem
                        .bind(null, state, setState, applyMarkupInContext),
                    toggleOperator : toggleOperator
                        .bind(null, state, setState, applyMarkupInContext),
                    selectFilters : selectFilters
                        .bind(null, state, setState, applyMarkupInContext)
                }
            }}
        >
            {children}
        </MarkupContext.Provider>
    );
}

MarkupContextProvider.propTypes = {
    children : PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};
