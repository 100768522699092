export default class EventEmitter {
    constructor() {
        this.events = {};
    }

    on(eventName, handler) {
        const event = this.events[eventName];

        if (event) event.push(handler);
        else this.events[eventName] = [ handler ];
    }

    emit(eventName, ...data) {
        const event = this.events[eventName];

        if (!event) return;
        for (const handler of event) handler(...data);
    }
}
