/* eslint-disable no-magic-numbers */
export class DialogService {
    static dialog;

    static closeAction

    static #showNotification(message) {
        console.log('MESSAGE', message, DialogService.dialog);
    }

    static #dialogCallback(asyncResult) {
        if (asyncResult.status === 'failed') {
            // In addition to general system errors, there are 3 specific errors for
            // displayDialogAsync that you can handle individually.
            switch (asyncResult.error.code) {
                case 12004:
                    DialogService.#showNotification('Domain is not trusted');
                    break;
                case 12005:
                    DialogService.#showNotification('HTTPS is required');
                    break;
                case 12007:
                    DialogService.#showNotification('A dialog is already opened.');
                    break;
                default:
                    DialogService.#showNotification(asyncResult.error.message);
                    break;
            }
        } else {
            DialogService.dialog = asyncResult.value;
            DialogService.dialog.addEventHandler(Office.EventType.DialogMessageReceived, DialogService.#messageHandler);

            DialogService.dialog.addEventHandler(Office.EventType.DialogEventReceived, DialogService.#eventHandler);
        }
    }

    static #messageHandler(arg) {
        DialogService.dialog.close();
        DialogService.#showNotification(arg.message);
    }

    static #eventHandler(arg) {
        switch (arg.error) {
            case 12002:
                DialogService.#showNotification('Cannot load URL, no such page or bad URL syntax.');
                break;
            case 12003:
                DialogService.#showNotification('HTTPS is required.');
                break;
            case 12006:
                DialogService.closeAction();
                DialogService.#showNotification('Dialog closed by user');
                break;
            default:
                DialogService.#showNotification('Undefined error in dialog window');
                break;
        }
    }

    static async openDialog({ id, iFrame = false, closeAction, openAction }) {
        if (!id) throw new Error('Id is required!');

        DialogService.closeAction = closeAction;

        // eslint-disable-next-line require-atomic-updates
        DialogService.dialog = await Office.context.ui.displayDialogAsync(
            // eslint-disable-next-line more/no-window
            `${window.location.origin}/assets/dialog.html?id=${id}`,
            { height: 80, width: 40, displayInIframe: iFrame },
            DialogService.#dialogCallback
        );

        openAction();
    }

    static async closeDialog() {
        await  DialogService.dialog?.close();
    }
}
