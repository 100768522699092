import ApiClient    from './ApiClient';
import Documents    from './Documents';
import Filters      from './Filters';
import References   from './References';
import Auth         from './Auth';
import Feedback     from './Feedback';

export default function apiFactory({ apiPrefix, apiUrl } = {}) {
    if (!apiPrefix) {
        throw new Error('[apiPrefix] required');
    }

    const api = new ApiClient({ apiPrefix, apiUrl });

    return {
        apiClient  : api,
        documents  : new Documents({ apiClient: api }),
        filters    : new Filters({ apiClient: api }),
        references : new References({ apiClient: api }),
        auth       : new Auth({ apiClient: api }),
        feedback   : new Feedback({ apiClient: api })
    };
}
