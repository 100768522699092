import { URL } from '../constants/api';
import Base    from './Base';

export default class Auth extends Base {
    getRole = async ({ documentPublicId }) => {
        const response = await this.apiClient.get({ endpoint: URL.USER_INFO, params: { documentPublicId } });

        return response;
    }

    logout = async () => {
        const response = await this.apiClient.post({ endpoint: URL.SIGN_OUT });

        return response;
    }
}
