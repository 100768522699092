import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from '@fluentui/react';

import {
    ERROR_CODE_TO_MESSAGE,
    DEFAULT_ERROR_MESSAGE
} from '../../../constants/errors';

import { useStoreFilter } from '../../contexts';
import FilterInput from './FilterInput';

import './FilterForm.css';

async function addFilter(parentId, filterValue, api) {
    if (!filterValue) return;

    try {
        await api.createFilter({
            parentId : parentId || null,
            value    : filterValue
        });
    } catch (error) {
        api.setError(ERROR_CODE_TO_MESSAGE[error.code] || DEFAULT_ERROR_MESSAGE);

        return;
    }
}

export default function FilterForm(props) {
    const { parentId, handleClose } = props;
    const { api } = useStoreFilter();
    const [ filterValue, setFilterValue ] = useState('');

    useEffect(() => {
        return () => {
            api.setError(null);
        };
    }, []);

    const handleAddFilter = useCallback(async (e) => {
        e.preventDefault();
        await addFilter(parentId, filterValue, api);
        setFilterValue('');
    }, [ parentId, filterValue ]);

    const closeInput = useCallback(() => {
        api.setSelectedFilterId(null);
        setFilterValue('');
        handleClose();
    }, []);

    return (
        <form className='filter-input-form' onSubmit={handleAddFilter}>
            <div className='filter-input-wrapper'>
                <FilterInput
                    isRoot={!parentId}
                    filterValue={filterValue}
                    setFilterValue={setFilterValue}
                />
                <i
                    className='ms-Icon ms-Icon--Cancel close-input'
                    onClick={closeInput}
                />
            </div>
            <PrimaryButton
                type='submit'
                className='filter-btn add-filter-btn'
                iconProps={{ iconName: 'add', style: { fontSize: '8px' } }}
                style={{ display: filterValue ? 'block' : 'none' }}
            >
                Add
            </PrimaryButton>
        </form>
    );
}

FilterForm.propTypes = {
    parentId    : PropTypes.number,
    handleClose : PropTypes.func.isRequired
};

FilterForm.defaultProps = {
    parentId : null
};
