/* eslint-disable more/no-hardcoded-configuration-data */
export const PUBLIC_ID_XML_NAMESPACE = 'http://igrd.id';
export const DOC_STATUS_XML_NAMESPACE = 'http://igrd.status';

export const PARENT_URL_SETTING = 'iGRD.ParentUrl';
export const LS_GENERATION_PARAMS_KEY = 'iGRD.GenerationParams';
export const LS_SELECTED_FILTERS_KEY = 'iGRD.SelectedFiltersTree';
/* eslint-enable more/no-hardcoded-configuration-data */

export const DOCUMENT_STATUSES = [ 'Digitized', 'In progress', 'Not digitized' ];
