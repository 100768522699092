/* eslint-disable react/no-multi-comp */

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@fluentui/react';

import FilterCheckbox from './FilterCheckbox';

import '../markup/MarkupList.css';

function GenerateChecklistItem(props) {
    const { filter, depth, filterSelector, isOpen = true } = props;
    const [ showSubfilters, setShowSubfilters ] = useState(isOpen);
    const handleExpandClick = useCallback(
        () => setShowSubfilters(!showSubfilters),
        [ showSubfilters ]
    );

    const labelStyle = !depth ? { fontWeight: 600 } : null;
    const itemIconName = `Chevron${showSubfilters ? 'Down' : 'Right'}`;

    return (
        <>
            <div className='markup-list-item'>
                <FilterCheckbox
                    filterId={filter.id}
                    filterSelector={filterSelector}
                />
                <div className='markup-label-wrapper'>
                    {!!filter.subfilters.length && (
                        <Icon
                            iconName={itemIconName}
                            onClick={handleExpandClick}
                        />
                    )}
                    <div className='markup-label' style={labelStyle}>
                        {filter.value}
                    </div>
                </div>
            </div>
            {
                showSubfilters && (
                    <GenerateChecklist
                        filters={filter.subfilters}
                        depth={depth + 1}
                        filterSelector={filterSelector}
                    />
                )
            }
        </>
    );
}

export default function GenerateChecklist(props) {
    const { filters, depth, filterSelector, isOpen } = props;

    return (
        <ul className='markup-list'>
            {filters.map(filter => (
                <li key={filter.id}>
                    <GenerateChecklistItem
                        key={filter.id}
                        filter={filter}
                        depth={depth}
                        filterSelector={filterSelector}
                        isOpen={isOpen}
                    />
                </li>
            ))}
        </ul>
    );
}

GenerateChecklistItem.propTypes = {
    filter : PropTypes.shape({
        id         : PropTypes.number.isRequired,
        value      : PropTypes.string.isRequired,
        subfilters : PropTypes.array.isRequired
    }).isRequired,
    depth          : PropTypes.number.isRequired,
    filterSelector : PropTypes.object.isRequired,
    isOpen         : PropTypes.bool
};

GenerateChecklist.propTypes = {
    filters : PropTypes.arrayOf(PropTypes.shape({
        id         : PropTypes.number.isRequired,
        value      : PropTypes.string.isRequired,
        subfilters : PropTypes.array.isRequired
    })).isRequired,
    depth          : PropTypes.number.isRequired,
    filterSelector : PropTypes.object.isRequired,
    isOpen         : PropTypes.bool
};

GenerateChecklistItem.defaultProps = {
    isOpen : true
};

GenerateChecklist.defaultProps = {
    isOpen : true
};
