/*
eslint-disable
no-nested-ternary,
no-magic-numbers,
more/no-numeric-endings-for-variables
*/
function uint6ToB64(nUint6) {
    return nUint6 < 26
        ? nUint6 + 65
        : nUint6 < 52
            ? nUint6 + 71
            : nUint6 < 62
                ? nUint6 - 4
                : nUint6 === 62
                    ? 43
                    : nUint6 === 63
                        ? 47
                        : 65;
}

export function base64EncArr(aBytes) {
    let nMod3 = 2;

    let sB64Enc = '';

    /* eslint-disable-next-line more/no-c-like-loops */
    for (let nLen = aBytes.length, nUint24 = 0, nIdx = 0; nIdx < nLen; nIdx++) {
        nMod3 = nIdx % 3;

        if (nIdx > 0 && ((nIdx * 4) / 3) % 76 === 0) {
            sB64Enc += '\r\n';
        }

        nUint24 |= aBytes[nIdx] << ((16 >>> nMod3) & 24);

        if (nMod3 === 2 || aBytes.length - nIdx === 1) {
            sB64Enc += String.fromCodePoint(
                uint6ToB64((nUint24 >>> 18) & 63),
                uint6ToB64((nUint24 >>> 12) & 63),
                uint6ToB64((nUint24 >>> 6) & 63),
                uint6ToB64(nUint24 & 63)
            );
            nUint24 = 0;
        }
    }

    return sB64Enc.slice(0, sB64Enc.length - 2 + nMod3) + (nMod3 === 2 ? '' : nMod3 === 1 ? '=' : '==');
}
/*
eslint-enable
no-nested-ternary,
no-magic-numbers,
more/no-numeric-endings-for-variables
*/
