import * as React from 'react';
import PropTypes from 'prop-types';
import { Spinner, SpinnerSize } from '@fluentui/react';

import './Progress.css';
// TODO: REDESIGN
export default function Progress(props) {
    const { logo, message, title } = props;

    return (
        <section className='ms-welcome__progress ms-u-fadeIn500 proggress_wrapper'>
            <img
                width='150'
                height='150'
                src={logo}
                alt={title}
                title={title}
            />
            <h1 className='ms-fontSize-su ms-fontWeight-light ms-fontColor-neutralPrimary'>
                {title}
            </h1>
            <Spinner size={SpinnerSize.large} label={message} />
        </section>
    );
}

Progress.propTypes = {
    logo    : PropTypes.string.isRequired,
    message : PropTypes.string.isRequired,
    title   : PropTypes.string.isRequired
};
