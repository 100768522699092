import * as React from 'react';
import PropTypes from 'prop-types';
import { PrimaryButton } from '@fluentui/react';
import { FontIcon } from '@fluentui/react/lib/Icon';

import { PROGRESS_KEY } from '../../../constants/progress';

import './Progress.css';

export default function RefsUpdateError(props) {
    const { logo, title, setStep } = props;
    const closeError = React.useCallback(() => setStep(PROGRESS_KEY.DEFAULT));

    return (
        <section className='ms-welcome__progress ms-u-fadeIn500 proggress_wrapper'>
            <img
                width='150'
                height='150'
                src={logo}
                alt={title}
                title={title}
            />
            <h1 className='ms-fontSize-su ms-fontWeight-light ms-fontColor-neutralPrimary'>
                {title}
            </h1>
            <div className='refs-error'>
                <FontIcon className='refs-error-icon ms-fontColor-themePrimary' aria-label='Error' iconName='Error' />
                <p className='refs-error-heading ms-fontColor-themePrimary'>Failed to update the references database.</p>
                <p>
                    Please, try generating once again.
                </p>
            </div>
            <PrimaryButton text='OK' onClick={closeError} />
        </section>
    );
}

RefsUpdateError.propTypes = {
    logo    : PropTypes.string.isRequired,
    title   : PropTypes.string.isRequired,
    setStep : PropTypes.func.isRequired
};
