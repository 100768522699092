import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { useStoreFilter } from '../../contexts';

import './NewFilterBtn.css';

export default function NewFilterBtn(props) {
    const { setShowNewFilterInput } = props;
    const { api } = useStoreFilter();

    const handleNewClick = useCallback(() => {
        setShowNewFilterInput(true);
        api.setSelectedFilterId(null);
    }, []);

    return (
        <button
            className='new-filter-btn'
            onClick={handleNewClick}
        >
            <img src='assets/new-filter-btn-icon.svg' alt='' />
            <span>New</span>
        </button>
    );
}

NewFilterBtn.propTypes = {
    setShowNewFilterInput : PropTypes.func.isRequired
};
