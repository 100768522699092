import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@fluentui/react';

import './GenerateSpinner.css';

export default function GenerateSpinner(props) {
    const { loadedPercentage } = props;
    const spinnerStyles = {
        circle : {
            borderColor : '#005FB8 #005FB8 rgb(199, 224, 244)',
            width       : 60,
            height      : 60
        }
    };

    return (
        <div className='spinner-wrapper'>
            <p className='spinner-title'>Please wait</p>
            <Spinner
                label={`${loadedPercentage}%`}
                styles={spinnerStyles}
            />
        </div>
    );
}

GenerateSpinner.propTypes = {
    loadedPercentage : PropTypes.number.isRequired
};
